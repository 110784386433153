import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService, { ActivityEvent } from "../../services/caseService";
import { EMAIL } from "../../constants/caseActivityTypes";

const useCaseActivityEmail = (caseActivity: ActivityEvent) =>
    useQuery(
        [queryKeys.caseActivityEmail, caseActivity.caseActivityId],
        () => caseService.fetchCaseActivityEmail(caseActivity.caseId, caseActivity.caseActivityId),
        {
            enabled: !!caseActivity?.caseActivityId && caseActivity.itemType === EMAIL,
            staleTime: Infinity,
        }
    );

export default useCaseActivityEmail;
