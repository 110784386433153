import { SET_PAGE } from "../types";

export function setPage({ path, url, params }) {
  return {
    type: SET_PAGE,
    payload: {
      path,
      url,
      params,
    },
  };
}
