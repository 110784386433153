import React from "react";
import { useLocation } from "react-router-dom";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { ReactComponent as ErrorIllustration } from "../images/error_illustration.svg";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  grid: {
    height: "100%",
    padding: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
  },
  logoWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1),
    maxWidth: "300px",
  },
  logo: {
    width: "100%",
    color: theme.palette.secondary.main,
  },
  errorType: {
    color: grey[500],
  },
  contact: {
    marginTop: theme.spacing(2),
  },
}));

const useQueryParams = () => new URLSearchParams(useLocation().search);

function ErrorPage() {
  const classes = useStyles();
  const queryParams = useQueryParams();
  const errorType = queryParams.get("type");

  return (
    <Grid
      className={classes.grid}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={6} xl={3}>
        <Paper className={classes.card}>
          <div className={classes.logoWrapper}>
            <ErrorIllustration className={classes.logo} />
          </div>
          <Typography className={classes.heading} variant="h3">
            Oops!
          </Typography>
          <Typography>Something went wrong.</Typography>
          {errorType && (
            <Typography className={classes.errorType} variant="body2">
              Error Type: {errorType}
            </Typography>
          )}
          <Typography className={classes.contact}>
            Please contact client support for help.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ErrorPage;
