import {
  SELECT_ACCOUNT,
  SELECT_SITE,
  SET_SITES,
  SET_ROOT_ACCOUNTS,
  HIDE_CASENEST_TERMS_BANNER,
} from "../types";
import { Account, RootAccount } from "../../services/accountService";

type AccountState = {
  rootAccounts: RootAccount[];
  selectedSite: Account | null;
  selectedAccount: RootAccount | null;
  hideCaseNestTermsBanner: boolean;
  sites: { [key: string]: Account };
};

const initialState: AccountState = {
  rootAccounts: [],
  selectedSite: null,
  selectedAccount: null,
  hideCaseNestTermsBanner: false,
  sites: {},
};

function accountReducer(state = initialState, action): AccountState {
  switch (action.type) {
    case SET_ROOT_ACCOUNTS:
      return { ...state, rootAccounts: action.payload };
    case SELECT_SITE:
      return { ...state, selectedSite: action.payload };
    case SELECT_ACCOUNT:
      return { ...state, selectedAccount: action.payload, selectedSite: null };
    case HIDE_CASENEST_TERMS_BANNER:
      return { ...state, hideCaseNestTermsBanner: true };
    case SET_SITES: {
      const sites = {};

      for (const site of action.payload.sites) {
        sites[site.externalId] = { ...site };
      }

      return {
        ...state,
        sites: { ...state.sites, ...sites },
      };
    }
    default:
      return state;
  }
}

export default accountReducer;
