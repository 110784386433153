import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import tutorialService from "../../services/tutorialService";

const useActiveTutorials = () =>
  useQuery([queryKeys.tutorials], () => tutorialService.fetchAllActive(), {
    staleTime: Infinity,
  });

export default useActiveTutorials;
