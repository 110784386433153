import { useMemo } from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    Tooltip,
    Typography,
    Link,
    makeStyles,
    Box,
    CircularProgress,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { NOTE, MISC, EMAIL, CALL_WITH_NOTE } from "../../constants/caseActivityTypes";
import { ActivityEvent } from "../../services/caseService";
import { format } from "date-fns";
import useCaseActivityEmail from "../../hooks/queries/useCaseActivityEmail";

const useStyles = makeStyles((theme) => ({
    dialogText: {
        color: "black",
    },
    cursorStylePointer: {
        cursor: "pointer",
    },
    searchResultWrapper: {
        marginLeft: theme.spacing(2),
        whiteSpace: "pre-line",
    },
    boldText: {
        fontWeight: "bold",
    },
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
    },
    loadingContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        marginBottom: theme.spacing(2),
    },
}));

type CaseActivityPreviewDialogProps = {
    open: boolean;
    caseActivity: ActivityEvent;
    onClose: () => void;
    openDocument: (file: any) => Promise<void>;
};

const CaseActivityPreviewDialog = ({
    open,
    caseActivity,
    onClose,
    openDocument,
}: CaseActivityPreviewDialogProps) => {
    const classes = useStyles();

    if (!open || !caseActivity) return null;

    const { data: caseActivityContent, isLoading } = useCaseActivityEmail(caseActivity);

    const caseActivityWithContent = useMemo(() => {
        if (!caseActivityContent)
            return caseActivity;

        return { ...caseActivity, ...caseActivityContent };

    } , [caseActivity, caseActivityContent]);

    const noteText =
        (caseActivity.itemType === NOTE || caseActivity.itemType === CALL_WITH_NOTE) && !caseActivity.htmlNote
            ? `<div>
                    <div style="font-weight: bold;">${caseActivity.issue && "Issue"}</div>
                    <div>${caseActivity.issue}</div>
                    <div style="font-weight: bold;">${caseActivity.advice && "Advice"}</div>
                    <div>${caseActivity.advice}</div>
                    <div style="font-weight: bold;">${caseActivity.action && "Action"}</div>
                    <div>${caseActivity.action}</div>
                </div>`
            : caseActivity.htmlNote
                ? caseActivity.htmlNote
                : caseActivity.itemType === MISC
                    ? `<div>
                        <div style="font-weight: bold;">${caseActivity.miscActivityTypeDescription}</div>
                        <div>${caseActivity.content}</div>
                        </div>`
                    : caseActivityWithContent.content;

    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
            <DialogContent>
                <DialogContentText className={classes.dialogText}>
                    {caseActivity.attachments &&
                        caseActivity.attachments.map((a) => (
                            <Typography key={a.reference} component="span">
                                <AttachFileIcon fontSize="small" />
                                <Tooltip title={a.filename}>
                                    <Link
                                        className={classes.cursorStylePointer}
                                        underline="hover"
                                        onClick={() => openDocument(a)}
                                    >
                                        {a.filename}
                                    </Link>
                                </Tooltip>
                            </Typography>
                        ))}
                    {caseActivity.itemType === EMAIL && (
                        <div className={classes.dialogContainer}>
                            <div>
                                <span className={classes.boldText}>From: </span>
                                {caseActivity.fromAddress
                                    ? caseActivity.fromAddress
                                    : caseActivity.person}
                            </div>
                            <div>
                                <span className={classes.boldText}>Sent: </span>
                                {format(new Date(caseActivity.eventTime), "dd/MM/yyyy")}
                            </div>
                            <div>
                                <span className={classes.boldText}>To: </span>
                                {caseActivity.toAddress}
                            </div>
                            <div>
                                <span className={classes.boldText}>Subject: </span>
                                {caseActivity.subject}
                            </div>
                        </div>
                    )}
                    <Typography
                        className={classes.searchResultWrapper}
                        component="span"
                        dangerouslySetInnerHTML={{__html: noteText}}
                    ></Typography>
                    {
                        isLoading &&
                        <Box className={classes.loadingContainer}>
                            <CircularProgress data-testid="loading-spinner" color="primary" />
                        </Box>
                    }
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default CaseActivityPreviewDialog;
