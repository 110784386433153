import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import tutorialService from "../../services/tutorialService";

const useTutorialViews = () =>
  useQuery([queryKeys.tutorialViews], () =>
    tutorialService.fetchViewsForUser()
  );

export default useTutorialViews;
