import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

type CaseNestAccountStructure = {
  account: CaseNestAccountStructureItem;
  childAccounts: CaseNestAccountStructureItem[];
  accountIds: number[];
};

type CaseNestAccountStructureItem = {
  accountId: number;
  name: string;
  childAccounts: CaseNestAccountStructureItem[];
};

type CaseNestAccountSummary = {
  accountId: number;
  name: string;
  address: string;
  addressStreet: string;
  addressCity: string;
  addressCounty: string;
  phone: string;
  sectorName: string;
  externalId: string;
  postCode: string;
  status:
    | "Active"
    | "Pending"
    | "N/A";
  numberOfSites: number;
  numberOfEmployees?: number;
  lastUpdated?: string;
  accountStructure: CaseNestAccountStructure;
};

const fetchAccountDetails = (accountExternalId) =>
  axios.get<never, CaseNestAccountSummary>(
    `${apiUrl}/client/getaccountsummary`,
    {
      params: {
        externalId: accountExternalId,
      },
    }
  );

const caseNestAccountService = {
  fetchAccountDetails,
};

export default caseNestAccountService;
