import {
  Tune as DashboardIcon,
  DynamicFeed as MyCasesIcon,
} from "@material-ui/icons";
import Dashboard from "./pages/Dashboard";
import Cases from "./pages/Cases";
import Case from "./pages/Case";
import AccountSelect from "./pages/AccountSelect";
import ErrorPage from "./pages/ErrorPage";

type MenuItem = {
  label: string;
  path: string;
  icon: any;
  children?: MenuItem[];
};

export const menuItems: MenuItem[] = [
  {
    label: "Dashboard",
    path: "/",
    icon: DashboardIcon,
  },
  {
    label: "Cases",
    path: `/cases`,
    icon: MyCasesIcon,
  },
];

export const routes = [
  {
    label: "Dashboard",
    path: "/",
    component: Dashboard,
    exact: true,
  },
  {
    label: "Cases",
    path: `/cases`,
    component: Cases,
    exact: true,
  },
  {
    label: "Dashboard",
    path: `/case/:caseId`,
    component: Case,
    exact: true,
  },
  {
    label: "Account Select",
    path: "/account-select",
    component: AccountSelect,
  },
  {
    label: "Error",
    path: "/error",
    component: ErrorPage,
  },
];
