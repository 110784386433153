import {useMutation} from "react-query";
import caseService, { FetchCaseActivitiesResponse, UpdateCustomerNoteParams} from "../../services/caseService";
import queryKeys from "../../constants/queryKeys";
import reactQueryUtils from "../../utils/reactQueryUtils";
import {getFileExtension} from "../../utils/fileNameUtils";
import {useSnackbar} from "notistack";

const useUpdateCustomerNote = () => {
    const { enqueueSnackbar } = useSnackbar();
    
    return useMutation({
        mutationFn: (note: UpdateCustomerNoteParams) => caseService.updateCustomerNote(note),
        onMutate: async (note: UpdateCustomerNoteParams) => {
            const queryKey = [queryKeys.caseActivities, note.caseId];
            reactQueryUtils.client.cancelQueries(queryKey);
            const previousActivities : FetchCaseActivitiesResponse | undefined = reactQueryUtils.client.getQueryData(queryKey);
            if (previousActivities?.caseActivities.some(x => x.caseActivityId === note.caseActivityId))
                reactQueryUtils.client.setQueryData(queryKey, (prev : FetchCaseActivitiesResponse) => ({
                    ...prev,
                    caseActivities: prev.caseActivities.map(x => x.caseActivityId === note.caseActivityId ? ({
                        ...x,
                        isSyncing: true,
                        content: note.message,
                        attachments: [
                            ...x.attachments.filter(a => !note.filesToDelete?.includes(a.reference)),
                            ...(note.newAttachments?.map(a => ({
                                reference: a.id,
                                filename: a.file?.name,
                                contentType: a.file?.type,
                                extension: getFileExtension(a.file?.name || "").replaceAll(".", "")
                            })) || []),
                        ],
                        activityDescription: note.subject,
                    }) : x)
                }));
            return { previousActivities, queryKey };
        },
        onError: (err, variables, context) => {
            enqueueSnackbar("Failed to update Customer Note", { variant: "error" });
            if (context?.previousActivities)
                reactQueryUtils.client.setQueryData(context.queryKey, context.previousActivities);
        },
        onSuccess: () => { enqueueSnackbar("Customer Note updated") },
        onSettled: (data, err, newDetails, context) => {
            if (context?.queryKey)
                reactQueryUtils.client.invalidateQueries(context.queryKey);
        },
    })
}

export default useUpdateCustomerNote;