import { useMutation } from "react-query";
import tutorialService from "../../services/tutorialService";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";

const useAddTutorialView = () =>
  useMutation(tutorialService.addViewForUser, {
    onSettled: () => {
      reactQueryUtils.client.invalidateQueries([queryKeys.tutorialViews]);
    },
  });

export default useAddTutorialView;
