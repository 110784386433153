import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const useCaseFiles = (caseId) =>
  useQuery<any, any>(
    [queryKeys.caseFiles, caseId],
    () => caseService.fetchCaseFiles(caseId),
    {
      enabled: !!caseId,
    }
  );

export default useCaseFiles;
