import {
  Card,
  CardContent,
  Typography,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import avatarStyles from "../theme/avatars";
import { CaseExternalContact } from "../services/caseService";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  titleWrapper: {
    flexGrow: 1,
  },
  contactsContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "100%",
  },
  contactContainer: {
    textAlign: "center",
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

type ExternalContactsCardProps = {
  externalContacts: CaseExternalContact[];
};

const ExternalContactsCard = ({
  externalContacts,
}: ExternalContactsCardProps) => {
  const classes = useStyles();
  const avatarClasses = avatarStyles();
  return (
    <Card>
      <CardContent>
        <div className={classes.titleContainer}>
          <div className={classes.titleWrapper}>
            <Typography variant="h6" gutterBottom>
              External Contacts
            </Typography>
          </div>
        </div>

        <div className={classes.contactsContainer}>
          {externalContacts &&
            externalContacts.map((contact) => (
              <div
                key={contact.externalContactId}
                className={classes.contactContainer}
              >
                <Avatar
                  alt={contact.name}
                  src=""
                  className={avatarClasses.small}
                />

                <Typography variant="subtitle2">{contact.name}</Typography>

                <Typography variant="body2">{contact.contactType}</Typography>
              </div>
            ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ExternalContactsCard;
