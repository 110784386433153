import axios, { setAuthHeader } from "../utils/axios";

const userEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/user`;

const verifyUser = async (token) => {
  setAuthHeader(token.idToken);
  return axios.get<never, any>(`${userEndpointUrl}/verify`);
};

const getUserProfile = () =>
  axios.get<never, any>(
    `${process.env.REACT_APP_CASENEST_API_URL}/client/getuserprofile`
  );

const userService = {
  verifyUser,
  getUserProfile,
};

export default userService;
