import { useQuery } from "react-query";
import teamService from "../../services/teamService";
import queryKeys from "../../constants/queryKeys";

const useELTeam = ({ accountExternalIds }) =>
  useQuery(
    [queryKeys.eLTeam, accountExternalIds.join(",")],
    () => teamService.getELTeam(accountExternalIds),
    {
      enabled: !!accountExternalIds?.length,
    }
  );

export default useELTeam;
