import { useState, useEffect } from "react";
import {
    Box,
    CircularProgress,
    makeStyles,
    Button,
    Grid,
    Tabs,
    Tab,
    Paper,
} from "@material-ui/core";
import { useTracking } from "react-tracking";
import { useHistory } from "react-router-dom";
import useCaseDetails from "../hooks/queries/useCaseDetails";
import { ArrowBackIosRounded } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import useInitialData from "../hooks/queries/useInitialData";
import CaseSummaryCard from "../components/CaseSummaryCard";
import EmployeeCard from "../components/EmployeeCard";
import ContactsCard from "../components/ContactsCard";
import TabPanel from "../components/TabPanel";
import WorkflowCard from "../components/WorkflowCard";
import CaseHistoryCard from "../components/CaseHistoryCard";
import LawyerCard from "../components/LawyerCard";
import EvidenceCard from "../components/EvidenceCard";
import FileViewerModal from "../components/FileViewerModal";
import CaseFilesCard from "../components/CaseFilesCard";
import CasePermissionsCard from "../components/CasePermissionsCard";
import CaseTime from "../components/CaseTime";
import PageBase from "../components/PageBase";
import CaseDescriptionCard from "../components/CaseDescriptionCard";
import ExternalContactsCard from "../components/ExternalContactsCard";
import OrganisationCard from "../components/OrganisationCard";
import ETClaimDetails from "../components/ETClaimDetails";

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
    },
    tabsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    column: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center"
    },
    rightHeader: {
        display: "flex",
        alignItems: "center",
    },
}));

export type FilePreview = {
    name: string;
    path: string;
    type: string;
    azureGuid: string;
};

const Case = () => {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams<any>();
    const caseId = parseInt(params.caseId);
    const { Track } = useTracking({
        page: `Case: ${caseId}`,
    });

    const [fileSelectedToOpen, setFileSelectedToOpen] = useState<FilePreview | null>(null);
    const [currentTab, setCurrentTab] = useState(0);
    const initialData = useInitialData();
    const caseDetails = useCaseDetails({ caseId });

    useEffect(() => { console.log({ fileSelectedToOpen }) }, [fileSelectedToOpen]);    

    const handleCloseFileViewer = () => {
        setFileSelectedToOpen(null);
    };

    const handleTabChange = (e, newValue) => {
        setCurrentTab(newValue);
    };

    if (caseDetails.isLoading || initialData.isLoading)
        return (
            <Box className={classes.loadingContainer}>
                <CircularProgress data-testid="loading-spinner" color="primary" />
            </Box>
        );

    if (caseDetails.isError || initialData.isError)
        return (
            <Box className={classes.loadingContainer}>
                <Alert variant="outlined" severity="error">
                    Could not load case
                </Alert>
            </Box>
        );

    const hasRequiredData = caseDetails.data && initialData.data;

    return (
        <PageBase>
            <Track>
                <div className={classes.header}>
                    <Button
                        variant="outlined"
                        onClick={() => history.goBack()}
                        color="primary"
                        startIcon={<ArrowBackIosRounded />}
                    >
                        Back To Cases
                    </Button>
                    <div className={classes.rightHeader}>
                        {caseDetails.isFetching && (
                            <CircularProgress size={24} color="primary" />
                        )}
                    </div>
                </div>
                <Paper className={classes.tabsContainer}>
                    <Tabs value={currentTab} onChange={handleTabChange}>
                        <Tab label="Details" />
                        <Tab label="Case Files" />
                        {caseDetails.data?.isET && (
                            <Tab value={2} label="ET Claim Detail" />
                        )}
                    </Tabs>
                </Paper>
                <TabPanel value={currentTab} index={0}>
                    <Grid container spacing={2}>
                        {hasRequiredData && (
                            <>
                                <Grid item xs={12} md={3}>
                                    <div className={classes.column}>
                                        <CaseSummaryCard caseDetails={caseDetails.data} />
                                        <CaseDescriptionCard
                                            description={caseDetails.data.description}
                                        />
                                        <EmployeeCard employees={caseDetails.data.employees} />
                                        <ContactsCard contacts={caseDetails.data.contacts} />
                                        <ExternalContactsCard
                                            externalContacts={caseDetails.data.externalContacts}
                                        />
                                        <LawyerCard caseAdvisers={caseDetails.data.advisers} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={classes.column}>
                                        <WorkflowCard
                                            stages={caseDetails.data.stages}
                                            tasks={caseDetails.data.tasks}
                                        />
                                        <CaseHistoryCard
                                            caseId={caseId}
                                            isUnitisedTime={caseDetails.data.isUnitisedTime}
                                            openFileViewer={setFileSelectedToOpen}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <div className={classes.column}>
                                        <CasePermissionsCard
                                            isVisibleToContactsOnly={
                                                caseDetails.data.isVisibleToContactsOnly
                                            }
                                        />
                                        <CaseTime caseId={caseId} />
                                        <OrganisationCard account={caseDetails.data.account} />                                        
                                        <EvidenceCard
                                            files={caseDetails.data.evidenceFiles}
                                            openFileViewer={setFileSelectedToOpen}
                                        />
                                    </div>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    <CaseFilesCard
                        caseId={caseId}
                        openFileViewer={setFileSelectedToOpen}                        
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    <ETClaimDetails caseId={caseId} />
                </TabPanel>                
                {
                    fileSelectedToOpen?.path &&
                    <FileViewerModal
                        open={!!fileSelectedToOpen.path}
                        fileType={fileSelectedToOpen.type}
                        filePath={fileSelectedToOpen.path}
                        fileName={fileSelectedToOpen.name}
                        onClose={handleCloseFileViewer}
                        caseId={caseId}
                        azureGuid={fileSelectedToOpen.azureGuid}
                    />
                }
            </Track>
        </PageBase>
    );
};

export default Case;
