import { makeStyles } from "@material-ui/core/styles";
import { green, red, yellow, blue, purple } from "@material-ui/core/colors";

const chipStyles = makeStyles(() => ({
  root: {
    color: "white",
  },
  green: {
    color: "white",
    backgroundColor: green[500],
  },
  red: {
    color: "white",
    backgroundColor: red.A700,
  },
  amber: {
    color: "white",
    backgroundColor: yellow.A700,
  },
  blue: {
    color: "white",
    backgroundColor: blue[700],
  },
  purple: {
    color: "white",
    backgroundColor: purple[500],
  },
}));

export default chipStyles;
