import {
  SET_TABLE_PAGE,
  SET_CASES_TABLE_PAGE,
  SET_CONTACT_CASES_TABLE_PAGE,
} from "../types";

export function setTablePage({ key, page, rowsPerPage }) {
  return {
    type: SET_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
    },
  };
}

export function setCasesTablePage({
  key,
  page,
  rowsPerPage,
  displayAll,
  displayClosed,
  caseNumber,
  adviser,
  employee,
  description,
  primaryContact,
  caseType,
}) {
  return {
    type: SET_CASES_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
      displayAll,
      displayClosed,
      caseNumber,
      adviser,
      employee,
      description,
      primaryContact,
      caseType,
    },
  };
}

export function setContactCasesTablePage({
  key,
  page,
  rowsPerPage,
  displayAll,
}) {
  return {
    type: SET_CONTACT_CASES_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
      displayAll,
    },
  };
}
