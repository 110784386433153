import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppProviders from "./components/AppProviders";
import { CssBaseline } from "@material-ui/core";
import "react-perfect-scrollbar/dist/css/styles.css";

if (process.env.NODE_ENV !== "development")
  console.log(
    `Build ${process.env.REACT_APP_BUILD_NUMBER} (${process.env.REACT_APP_COMMIT_HASH})`
  );

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProviders>
      <CssBaseline />
      <App />
    </AppProviders>
  </React.StrictMode>
);
