import axios from "../utils/axios";
import fileUtils from "../utils/fileUtils";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;
const documentEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/documents`;

const retrieveDocumentPreviewPath = async ({ reference, contentType, filename }) => {
    const isVideoContent = contentType.startsWith("video");
    const endpointUrl = isVideoContent
        ? "client/downloadvideo"
        : "client/downloaddocument";

    try {
        const response = await axios.get<never, any>(
            `${apiUrl}/${endpointUrl}/${reference}?isForFilePreview=true&filename=${encodeURIComponent(filename)}`,
            {
                responseType: isVideoContent ? "stream" : "arraybuffer",
            }
        );

        if (response.byteLength === 0)
            return null;

        if (isVideoContent)
            return response;

        const blob = new Blob([response], { type: contentType });
        return window.URL.createObjectURL(blob);
    } catch (e) {
        throw new Error(e?.message || "Could not fetch document.");
    }
};

const downloadCookiePolicy = async () => {
    try {
        const response = await axios.get<never, any>(
            `${documentEndpointUrl}/downloadcookiepolicy`,
            { responseType: "arraybuffer" }
        );
        fileUtils.downloadFileFromBlob(response, "WorkNest Cookie Policy.docx");
    } catch (error) {
        throw error;
    }
};

const documentService = {
    retrieveDocumentPreviewPath,
    downloadCookiePolicy,
};

export default documentService;