import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseNestAccountService from "../../services/caseNestAccountService";

const useAccountDetails = ({ accountExternalId }) =>
  useQuery(
    [queryKeys.accountDetails, accountExternalId],
    () => caseNestAccountService.fetchAccountDetails(accountExternalId),
    {
      enabled: !!accountExternalId,
    }
  );

export default useAccountDetails;
