import {ChangeEvent, createContext, useContext, useMemo, useState} from "react";
import {getFileExtension} from "../utils/fileNameUtils";
import {nanoid} from "@reduxjs/toolkit";
import {UploadStatus} from "../constants/uploadStatus";
import storageService from "../services/storageService";

const supportedFileTypes = ['.jpeg', '.jpg', '.gif', '.png', '.doc', '.docx', '.pdf', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', '.msg', '.eml', '.csv', '.zip',  '.mp4', '.webm', '.ogg', '.mov', '.wav', '.mp3', '.m4a'];

const MAX_FILE_SIZE = 524288000;

type FileDetails = {
    name: string,
    size: number,
    type: string
}

export type Upload = {
    id: string,
    file: FileDetails | null,
    status: UploadStatus
}

type FailedUpload = {
    id: string,
    name: string,
    message: string
}

type FileUploadContext = {
    uploads: Upload[];
    setUploads: (uploads : Upload[]) => void;
    failedUploads: FailedUpload[];
    setFailedUploads: (failedUploads : FailedUpload[]) => void;
    handleFileUpload: (e : any) => void;
    handleClearFailedUpload: (id : string) => void;
    handleNewFileDelete: (fileId : string) => void;
    uploadInProgress : boolean;
};

const defaultContext: FileUploadContext = {
    uploads: [],
    setUploads: () => {},
    failedUploads: [],
    setFailedUploads: () => {},
    handleFileUpload: () => {},
    handleClearFailedUpload: () => {},
    handleNewFileDelete: () => {},
    uploadInProgress: false,
};

const FileUploadContext = createContext<FileUploadContext>(defaultContext);

const FileUploadProvider = ({ children }) => {
    const [uploads, setUploads] = useState<Upload[]>([]);
    const [failedUploads, setFailedUploads] = useState<FailedUpload[]>([]);

    const handleFileUpload = async (e) => {
        console.log(e);
        const files : File[] = [...e.target.files];

        setFailedUploads(prev => [
            ...prev,
            ...files.filter(file => !supportedFileTypes.includes(getFileExtension(file.name)))
                .map(file => ({ id: nanoid(), name: file.name, message: "Failed: File type not supported" })),
            ...files.filter(file => supportedFileTypes.includes(getFileExtension(file.name)) && file.size > MAX_FILE_SIZE)
                .map(file => ({ id: nanoid(), name: file.name, message : "Failed: File too large" }))
        ]);

        const acceptedFiles = files.filter(file => supportedFileTypes.includes(getFileExtension(file.name)) && file.size <= MAX_FILE_SIZE)
            .map(file => ({ id: nanoid(), file }));

        if (acceptedFiles.length === 0)
            return;

        setUploads(prev => [
            ...prev,
            ...acceptedFiles.map(x => ({
                ...x,
                file: {
                    name: x.file.name,
                    size: x.file.size,
                    type: x.file.type,
                },
                status: UploadStatus.QUEUED,
            }))
        ]);

        for (const file of acceptedFiles) {
            try  {
                setUploads(prev => prev.map(x => file.id === x.id ? ({
                    ...x,
                    status: UploadStatus.IN_PROGRESS
                }) : x));
                const reference = await storageService.upload(file.file)
                setUploads(prev => prev.map(x => file.id === x.id ? ({
                    ...x,
                    status: UploadStatus.COMPLETE,
                    id: reference
                }) : x));
            }
            catch (e) {
                setUploads(prev => prev.filter(x => x.id !== file.id));
                setFailedUploads(prev => [
                    ...prev,
                    {
                        id: file.id,
                        name: file.file.name,
                        message: "Failed: Unknown error. Try again or contact support"
                    }
                ]);
                console.error(e);
            }
        }
    };

    const handleNewFileDelete = async (fileId: string) => {
        const file = uploads.filter(x => x.id === fileId)[0];

        if (!file)
            return;

        if (file.status === UploadStatus.COMPLETE)
            await storageService.deleteBlob(fileId);

        setUploads(prev => prev.filter(x => x.id !== fileId));
    };

    const handleClearFailedUpload = (id : string) => {
        setFailedUploads(prev => prev.filter(file => file.id !== id));
    }

    const uploadInProgress : boolean = useMemo(() => {
        return uploads.some(x => x.status === UploadStatus.IN_PROGRESS);
    }, [uploads]);
    
    const contextValue = {
        uploads,
        setUploads,
        failedUploads,
        setFailedUploads,
        handleFileUpload,
        handleClearFailedUpload,
        handleNewFileDelete,
        uploadInProgress,
    };
    
    return (
        <FileUploadContext.Provider value={contextValue}>{children}</FileUploadContext.Provider>
    )
};

const useFileUploads = () => useContext(FileUploadContext);
export { useFileUploads, FileUploadProvider };