import axios, { AxiosError, AxiosResponse } from "axios";

const handleResponseSuccess = (response: AxiosResponse) => {
  return response.data;
};

const handleResponseError = (error: AxiosError) => {
  if (error.response) {
    // server responded with a non 2xx code
    return Promise.reject(error.response.data);
  } else if (error.request) {
    // request was made but no response received
    return Promise.reject(error);
  } else {
    // something else happened before making the request
    return Promise.reject(error);
  }
};

const instance = axios.create();

instance.interceptors.response.use(handleResponseSuccess, handleResponseError);

export const setAuthHeader = (accessToken) => {
  instance.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
};

export default instance;
