export const getExcerpt = (text, length = 200) => {
  if (!text) return;
  const excerpt = text.substr(0, length);

  if (!excerpt) return "...";

  return excerpt.length === length ? excerpt + "..." : excerpt;
};

export const buildQueryStringList = (name, arr) => {
  if (!arr) return "";
  return arr.length > 0 ? `?${name}=${arr.join(`&${name}=`)}` : "";
};

export const capitaliseFirstLetter = (string) => 
  string.charAt(0).toUpperCase() + string.slice(1)

const stringUtils = {
  getExcerpt,
  buildQueryStringList,
};

export default stringUtils;
