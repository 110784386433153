import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const requestCallback = (callback) =>
  axios.post(`${apiUrl}/client/requestclientcallback`, callback);

const callbackService = {
  requestCallback,
};

export default callbackService;
