import PerfectScrollbar from "react-perfect-scrollbar";
import CardBase, { CardBaseProps } from "./cards/CardBase";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import CaseHistoryItem from "./CaseHistoryItem";
import { ActivityEvent } from "../services/caseService";
import React, { ChangeEvent, useCallback } from "react";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    perfectScroll: {
        maxHeight: "100vh",
        marginRight: theme.spacing(-1),
        marginTop: theme.spacing(1),
    },
    historyItemWrapper: {
        paddingRight: theme.spacing(2),
    },
    pagination: {
        display: "flex",
        justifyContent: "end",
        color: theme.palette.primary.main,
    },
    newNoteBtn: {
        marginRight: theme.spacing(1),
    },
    seekAdviceButton: {
        margin: theme.spacing(0, 1),
    },
}));

type CaseHistoryComponentProps = CardBaseProps & {
    caseActivities: ActivityEvent[];
    count: number;
    valuesPerPage: number;
    numberOfPages?: number;
    pageNumber: number;
    isUnitisedTime: boolean;
    onPreviewClick: (caseEvent: ActivityEvent) => void;
    onPageChange: (event: ChangeEvent, value: number) => void;
    onNewNoteClick: (isOpen: boolean) => void;
    onSideMenuClick: (e: ActivityEvent, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isLoading: boolean;
    isFetching: boolean;
    setSeekAdviceOpen: (isOpen: boolean) => void;
    photos: {}
};

const CaseHistoryComponent = ({
    caseActivities,
    count,
    valuesPerPage,
    numberOfPages,
    pageNumber,
    isLoading,
    isUnitisedTime,
    error,
    onPreviewClick,
    onPageChange,
    onNewNoteClick,
    onSideMenuClick,
    isFetching,
    setSeekAdviceOpen,
    photos
}: CaseHistoryComponentProps) => {

    const classes = useStyles();

    const getPhoto = useCallback((caseEvent) => {
        if (caseEvent.direction === 0)
            return null;

        return photos[caseEvent.adviserId]
    }, [photos])

    return (
        <CardBase
            title={`Case History (${count} activit${count === 1 ? "y" : "ies"})`}
            rightComponent={
                <div>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => onNewNoteClick(true)}
                        className={classes.newNoteBtn}
                    >
                        New Note
                    </Button>
                    <Button onClick={() => setSeekAdviceOpen(true)} className={classes.seekAdviceButton} variant="contained" color="secondary">Seek Advice</Button>
                </div>

            }
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
        >
            <PerfectScrollbar className={classes.perfectScroll}>
                <div className={classes.historyItemWrapper}>
                    {
                        caseActivities.length > 0 &&
                        caseActivities
                            .map((activity) => (
                                <CaseHistoryItem
                                    getPhoto={getPhoto}
                                    key={activity.caseActivityId}
                                    caseEvent={activity}
                                    onPreviewClick={onPreviewClick}
                                    onSideMenuClick={onSideMenuClick}
                                    isUnitisedTime={isUnitisedTime}
                                />
                            ))}
                </div>
                {count > valuesPerPage && (
                    <Pagination
                        count={numberOfPages}
                        page={pageNumber}
                        onChange={onPageChange}
                        className={classes.pagination}
                        color="secondary"
                    />
                )}
            </PerfectScrollbar>
        </CardBase>
    );
};

export default CaseHistoryComponent;
