import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import pageReducer from "./pageReducer";
import tutorialReducer from "./tutorialReducer";
import cookieReducer from "./cookieReducer";
import tablePageReducer from "./tablePageReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  page: pageReducer,
  tutorial: tutorialReducer,
  cookie: cookieReducer,
  tablePage: tablePageReducer,
});

export default rootReducer;
