import { forwardRef, ForwardedRef } from "react";
import { Tile } from "../../services/tileService";
import { ListItemText, makeStyles, MenuItem } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { ReactComponent as MyWorkNestLogo } from "../../images/myWorkNest_Purple_Positive_RGB.svg";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  imageContainer: {
    width: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  descriptionContainer: {
    whiteSpace: "normal",
    flexGrow: 1,
  },
  appLogo: {
    width: "100%",
  },
}));

const nodeEnvToMyWorkNestEnv = {
  development: "test",
  test: "test",
  qa: "qa",
  preprod: "preprod",
  production: "app",
};

function QuickNavigationMenuItem({ app }: { app: Tile }, ref: ForwardedRef<HTMLAnchorElement>) {
  const classes = useStyles();

  const clickRoute =
    app.clickUrl ||
    `https://${
      nodeEnvToMyWorkNestEnv[process.env.REACT_APP_ENV]
    }.myworknest.com${app.internalRoute}`;

  return (
      <MenuItem className={classes.menuItem} component="a" href={clickRoute} ref={ref}>
      <div className={classes.imageContainer}>
        {app.imageUrl ? (
          <img
            alt={`${app.displayName} image`}
            src={app.imageUrl}
            className={classes.appLogo}
          />
        ) : (
          <MyWorkNestLogo />
        )}
      </div>
      <div className={classes.descriptionContainer}>
        <ListItemText primary={app.displayName} secondary={app.information} />
      </div>
      <ChevronRight />
    </MenuItem>
  );
}

export default forwardRef(QuickNavigationMenuItem);
