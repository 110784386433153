import {
  SELECT_ACCOUNT,
  SELECT_SITE,
  SET_SITES,
  SET_ROOT_ACCOUNTS,
  HIDE_CASENEST_TERMS_BANNER,
} from "../types";

export function selectSite(site) {
  return {
    type: SELECT_SITE,
    payload: site,
  };
}

export function selectAccount(account) {
  return {
    type: SELECT_ACCOUNT,
    payload: account,
  };
}

export function setRootAccounts(rootAccounts) {
  return {
    type: SET_ROOT_ACCOUNTS,
    payload: rootAccounts,
  };
}

export function setSites({ sites }) {
  return {
    type: SET_SITES,
    payload: { sites },
  };
}

export function hideCaseNestTermsBanner() {
  return {
    type: HIDE_CASENEST_TERMS_BANNER,
  };
}
