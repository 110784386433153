import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import {MiscActivityCategory} from "../constants/miscActivityCategory";

const CaseActivityMenu = ({
  contextEvent,
  contextAnchorEl,
  onContextMenuClose,
  onEditClick,
}) => (
  <Menu
    anchorEl={contextAnchorEl}
    keepMounted
    open={Boolean(contextAnchorEl)}
    onClose={onContextMenuClose}
  >
    {contextEvent &&
      contextEvent.miscActivityCategory == MiscActivityCategory.CustomerNote && (
        <MenuItem onClick={() => onEditClick(contextEvent)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
      )}
  </Menu>
);

export default CaseActivityMenu;
