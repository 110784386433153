import { useDashboardFilter } from "../contexts/dashboardContext";
import CardBase from "./cards/CardBase";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { formatTime } from "../utils/dateTimeUtils";
import useTimeSummary from "../hooks/queries/useTimeSummary";
import { useSelector } from "react-redux";
import React from "react";
import { RootState } from "../redux/store";

const useStyles = makeStyles((theme) => ({
  timeLineItemValue: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  lineItemsContainer: {
    marginTop: "6px",
  },
}));

function TimeSummaryCard() {
  const classes = useStyles();
  const { fromDate, toDate, displayAll } = useDashboardFilter();
  const { selectedSite, sites } = useSelector(
    (state: RootState) => state.account
  );

  const { data, isLoading, error, isFetching } = useTimeSummary({
    fromDate,
    toDate,
    displayAll,
    accountExternalIds: selectedSite
      ? [selectedSite.externalId]
      : Object.keys(sites),
  });

  const cardContent = () => {
    if (data)
      return (
        <Grid container className={classes.lineItemsContainer} spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Total Case Time</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.timeLineItemValue}
              variant="subtitle2"
            >
              {data && formatTime(data.totalCaseTime)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Total Subscription Time</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.timeLineItemValue}
              variant="subtitle2"
            >
              {data && formatTime(data.totalSubscriptionTime)}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Total Chargable Time</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.timeLineItemValue}
              variant="subtitle2"
            >
              {data && formatTime(data.totalChargableTime)}
            </Typography>
          </Grid>
        </Grid>
      );
    return <Typography>No Time Data</Typography>;
  };

  return (
    <CardBase
      title={
        displayAll ? "Time Breakdown - All Cases" : "Time Breakdown - My Cases"
      }
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      fullHeight
    >
      {cardContent()}
    </CardBase>
  );
}

export default TimeSummaryCard;
