import { QueryClient } from "react-query";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const reactQueryUtils = {
  client,
};

export default reactQueryUtils;
