import React from "react";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import CardBase from "../components/cards/CardBase";
import { grey } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import { selectAccount } from "../redux/actions/accountActions";
import { useHistory } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PageBase from "../components/PageBase";
import { RootState } from "../redux/store";

const useStyles = makeStyles((theme) => ({
  accountCard: {
    padding: theme.spacing(2),
    borderColor: grey[200],
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "4px",
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  currentAccountCard: {
    transition: "0.2s all ease-out",
    display: "flex",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5),
    border: `1px solid ${grey[300]}`,
    borderRadius: "4px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  currentAccountIndicator: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
  },
  currentAccountCardIcon: {
    color: "inherit",
    marginLeft: theme.spacing(1),
  },
  accountSelectButton: {
    transition: "0.2s all ease-out",
    display: "block",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5),
    background: "none",
    border: `1px solid ${grey[300]}`,
    borderRadius: "4px",
    width: "100%",
    cursor: "pointer",
    textAlign: "justify",
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
  cancelButton: {
    marginTop: theme.spacing(2),
  },
}));

function AccountSelect() {
  const history = useHistory();
  const { selectedAccount, rootAccounts } = useSelector(
    (state: RootState) => state.account
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRootAccountSelect = (account) => {
    dispatch(selectAccount(account));
    goToDashboard();
  };

  const goToDashboard = () => {
    history.push("/");
  };

  const Wrapper = ({ rootAccount, children }) => {
    if (rootAccount.account.id === selectedAccount?.account.id)
      return <div className={classes.currentAccountCard}>{children}</div>;

    return (
      <button
        className={classes.accountSelectButton}
        onClick={() => handleRootAccountSelect(rootAccount)}
      >
        {children}
      </button>
    );
  };

  return (
    <PageBase>
      <Container maxWidth="md">
        <CardBase title="Account Select" description="Select an account.">
          {rootAccounts.map((rootAccount) => (
            <Wrapper key={rootAccount.account.id} rootAccount={rootAccount}>
              <Typography>{rootAccount.account.name}</Typography>
              {rootAccount.account.id === selectedAccount?.account.id && (
                <div className={classes.currentAccountIndicator}>
                  <Typography variant="button">Current Account</Typography>
                  <CheckCircleIcon className={classes.currentAccountCardIcon} />
                </div>
              )}
            </Wrapper>
          ))}
          {selectedAccount?.account.id && (
            <Button
              className={classes.cancelButton}
              onClick={goToDashboard}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          )}
        </CardBase>
      </Container>
    </PageBase>
  );
}

export default AccountSelect;
