import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "../contexts/authContext";
import theme from "../theme";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import reactQueryUtils from "../utils/reactQueryUtils";
import { Provider } from "react-redux";
import store from "../redux/store";

const AppProviders = ({ children }) => {
  return (
    <Router>
      <Provider store={store}>
        <AuthProvider>
          <QueryClientProvider client={reactQueryUtils.client}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AuthProvider>
      </Provider>
    </Router>
  );
};

export default AppProviders;
