import React, { useEffect, useState } from "react";
import {
  Button,
  makeStyles,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import documentService from "../services/documentService";
import {
  getPardotConsentCookieValue,
  setPardotConsentCookie,
} from "../utils/cookieUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  setCookieJarOpen,
  setCookieJarStep,
} from "../redux/actions/cookieActions";
import { RootState } from "../redux/store";

const useStyles = makeStyles((theme) => ({
  policyText: {
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
  title: {
    color: theme.palette.primary.main,
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0px !important",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  dialogActions: {
    flexWrap: "wrap",
  },
}));

const CookieJar = () => {
  const [pardotConsent, setPardotConsent] = useState(false);
  const [pardotChecked, setPardotChecked] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen: open, step } = useSelector(
    (state: RootState) => state.cookie
  );

  const handleDownloadCookiePolicy = async () => {
    await documentService.downloadCookiePolicy();
  };

  const handleAcceptAll = () => {
    setPardotConsentCookie("true");

    if (pardotConsent) {
      dispatch(setCookieJarOpen({ isOpen: false }));
      return;
    }

    window.location.reload();
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    dispatch(setCookieJarOpen({ isOpen: false }));
  };

  const handleSaveAndClose = () => {
    setPardotConsentCookie(pardotChecked.toString());

    if (pardotChecked === pardotConsent) {
      dispatch(setCookieJarOpen({ isOpen: false }));
      return;
    }

    window.location.reload();
  };

  useEffect(() => {
    const currentPardotConsent = getPardotConsentCookieValue();

    if (currentPardotConsent === null) {
      dispatch(setCookieJarOpen({ isOpen: true }));
      return;
    }

    setPardotConsent(currentPardotConsent === "true");
    setPardotChecked(currentPardotConsent === "true");
  }, [open]);

  const InfoMessage = () => (
    <DialogContentText>
      We use cookies to improve your experience on our website. Read the{" "}
      <span className={classes.policyText} onClick={handleDownloadCookiePolicy}>
        WorkNest Cookie Policy
      </span>{" "}
      to find out more.
    </DialogContentText>
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      {step === "initial" && (
        <>
          <DialogTitle className={classes.title}>Cookies</DialogTitle>
          <DialogContent>
            <InfoMessage />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.button}
              color="secondary"
              variant="outlined"
              onClick={() => dispatch(setCookieJarStep({ step: "manage" }))}
            >
              Manage Cookies
            </Button>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              onClick={handleAcceptAll}
            >
              Accept All
            </Button>
          </DialogActions>
        </>
      )}
      {step === "manage" && (
        <>
          <DialogTitle className={classes.title}>Manage Cookies</DialogTitle>
          <DialogContent>
            <InfoMessage />
            <Typography variant="h6" className={classes.title}>
              Essential Cookies
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogContentText>
                ARRAffinity / ARRAffinitySameSite
              </DialogContentText>
              <Checkbox checked disabled />
            </Box>
            <Typography variant="h6" className={classes.title}>
              Non-essential Cookies
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogContentText>Pardot tracking</DialogContentText>
              <Checkbox
                checked={pardotChecked}
                onChange={(e: any) => setPardotChecked(e.target.checked)}
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.button}
              color="secondary"
              variant="outlined"
              onClick={handleSaveAndClose}
            >
              Save & Close
            </Button>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              onClick={handleAcceptAll}
            >
              Accept All
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CookieJar;
