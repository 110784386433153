import React, { ChangeEvent } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import useIsMobile from "../../hooks/useIsMobile";

type ResponsiveSelectProps<T> = {
  options: T[];
  optionValueKey?: keyof T;
  optionLabelKey?: string;
  optionLabelFunction?: (option: T) => string;
  onChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  [key: string]: any;
};

function ResponsiveSelect<T>({
  options,
  optionValueKey,
  optionLabelKey,
  optionLabelFunction,
  onChange,
  ...rest
}: ResponsiveSelectProps<T>) {
  const isMobile = useIsMobile();

  const displayOption = (selectOption: T, index: number) => {
    let value;
    let label: string;

    if (
      typeof selectOption === "object" &&
      optionValueKey &&
      selectOption !== null
    )
      value = selectOption[optionValueKey];
    else value = selectOption;

    if (typeof selectOption === "object" && selectOption !== null) {
      if (optionLabelKey) label = selectOption[optionLabelKey];
      else if (optionLabelFunction) label = optionLabelFunction(selectOption);
      else
        throw new Error(
          "optionLabelKey or optionLabelFunction must be provided"
        );
    } else label = selectOption as string;

    return isMobile ? (
      <option key={index} value={value}>
        {label}
      </option>
    ) : (
      <MenuItem key={index} value={value}>
        {label}
      </MenuItem>
    );
  };

  return (
    <TextField
      variant="outlined"
      select
      onChange={onChange}
      {...rest}
      SelectProps={{ native: isMobile }}
    >
      {options.map(displayOption)}
    </TextField>
  );
}

export default ResponsiveSelect;
