import { SET_IS_TUTORIAL_OPEN, SET_TUTORIAL } from "../types";
import { Tutorial } from "../../services/tutorialService";

type TutorialState = {
  isTutorialOpen: boolean;
  tutorial: Tutorial | null;
};

const initialState: TutorialState = {
  isTutorialOpen: false,
  tutorial: null,
};

function tutorialReducer(state = initialState, action): TutorialState {
  switch (action.type) {
    case SET_IS_TUTORIAL_OPEN:
      return {
        ...state,
        isTutorialOpen: action.payload.isOpen,
      };
    case SET_TUTORIAL:
      return {
        ...state,
        tutorial: action.payload.tutorial,
      };

    default:
      return state;
  }
}

export default tutorialReducer;
