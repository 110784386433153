import {
  Card,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@material-ui/core";
import { Skeleton, Alert } from "@material-ui/lab";
import { ETCaseClaim } from "../../../services/caseService";
import { BaseCardProps } from "../../../types/baseCardProps";

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: theme.spacing(3),
  },
  centerAligned: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "max-content",
  },
  space: {
    display: "flex",
    alignSelf: "start",
    columnGap: theme.spacing(3),
  },
  button: {
    width: "max-content",
  },
  skeletonCointainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  skeletonFields: {
    display: "flex",
    gap: theme.spacing(3),
    marginLeft: "auto",
  },
  fullWidth: {
    width: "100%",
  },
  noPadding: {
    padding: "0",
  },
  cellFlex: {
    display: "flex",
    gap: theme.spacing(2),
  },
  fullGrid: {
    borderBottom: `solid 2px ${theme.palette.secondary.main}`,
    gridColumn: "1 / span 4",
    display: "flex",
    alignItems: "center",
    height: "50px",
  },
  subtitle: {
    fontWeight: 600,
  },
  loadingErrorContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

export interface PercentageOfSuccessClaimTypeCardProps extends BaseCardProps {
  claims?: ETCaseClaim[];
}

export const PercentageOfSuccessClaimTypeCard = ({
  claims,
  isLoading,
  isError,
  error,
}: PercentageOfSuccessClaimTypeCardProps) => {
  const classes = useStyles();

  const successFrom = Math.min.apply(
    Math,
    claims?.map(function (claim) {
      return claim.successPercentage;
    })
  );
  const successTo = Math.max.apply(
    Math,
    claims?.map(function (claim) {
      return claim.successPercentage;
    })
  );

  return (
    <>
      <Card>
        <CardContent>
          <div className={classes.fullGrid}>
            <Typography variant="h6">
              Prospects of Success and Claim Type
            </Typography>
          </div>
          {isLoading && (
            <div className={classes.skeletonCointainer}>
              <div className={classes.skeletonFields}>
                <Skeleton height={40} width={200} variant="rect" />
                <Skeleton height={40} width={200} variant="rect" />
              </div>
              <div>
                <Skeleton height={40} width={200} variant="rect" />
              </div>
              <div className={classes.fullWidth}>
                <Skeleton height={40} variant="rect" />
              </div>
              <div className={classes.fullWidth}>
                <Skeleton height={40} variant="rect" />
              </div>
            </div>
          )}
          {isError && (
            <div className={classes.loadingErrorContainer}>
              <Alert variant="outlined" severity="error">
                {error?.message || "Unable to load ET case details"}
              </Alert>
            </div>
          )}
          {!isLoading && (
            <div className={classes.accordionDetails}>
              <div>
                <div className={classes.centerAligned}>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Overall Prospects of Success
                  </Typography>
                  <div className={classes.space}>
                    <Typography variant="body2">
                      From: {isFinite(successFrom) ? successFrom : "..."}%
                    </Typography>
                    <Typography variant="body2">
                      To: {isFinite(successTo) ? successTo : "..."}%
                    </Typography>
                  </div>
                </div>
              </div>
              <Table>
                <TableHead>
                  <TableCell className={classes.subtitle}>Claim Type</TableCell>
                  <TableCell className={classes.subtitle}>Percentage</TableCell>
                  <TableCell className={classes.subtitle}>
                    Covered by Insurance
                  </TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                  {claims?.map((claim) => (
                    <TableRow key={claim.claimId}>
                      <TableCell>
                        <Typography variant="body2">
                          {claim.claimTypeName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {claim.successPercentage}%
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {claim.isInsured ? "Yes" : "No"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  {(!claims || claims.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={3}>No Claim Data</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default PercentageOfSuccessClaimTypeCard;
