import axios from "../utils/axios";

const apiUrl = `${process.env.REACT_APP_CASENEST_API_URL}/client`;

const deleteBlob = async (reference) =>
    await axios.delete(`${apiUrl}/storage/${reference}`);

const upload = async (file) : Promise<string> => {
    let formData = new FormData();
    formData.append("file", file);
    return await axios.put<never, string>(`${apiUrl}/storage/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

const storageService = {
    deleteBlob,
    upload,
}

export default storageService;