import {
  Card,
  CardContent,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
  makeStyles,
  Chip,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { format } from "date-fns";
import {
  caseActionStatus,
  getCaseActionStatusClassName,
  getCaseActionStatusLabel,
} from "../../../utils/caseActionUtils";
import { green, red, amber } from "@material-ui/core/colors";
import { FetchOpenAndEtCaseActionsResponse } from "../../../services/caseService";
import { BaseCardProps } from "../../../types/baseCardProps";

const useStyles = makeStyles((theme) => ({
  blankCell: {
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  statusChip: {
    color: "white",
  },
  [caseActionStatus.COMPLETE.className]: {
    backgroundColor: green[500],
  },
  [caseActionStatus.OPEN.className]: {
    backgroundColor: red[600],
  },
  [caseActionStatus.PENDING.className]: {
    backgroundColor: amber[500],
  },
  fullGrid: {
    borderBottom: `solid 2px ${theme.palette.secondary.main}`,
    display: "flex",
    alignItems: "center",
    height: "50px",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  loadingErrorContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  chipContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtitle: {
    fontWeight: 600,
  },
}));

const actionSortFunction = (
  action1: FetchOpenAndEtCaseActionsResponse,
  action2: FetchOpenAndEtCaseActionsResponse
) => {
  if (action1.completedOn != null && action2.completedOn == null) return 1;

  if (action1.completedOn == null && action2.completedOn != null) return -1;

  return new Date(action1.due).valueOf() - new Date(action2.due).valueOf();
};

interface MilestonesProps extends BaseCardProps {
  data?: FetchOpenAndEtCaseActionsResponse[];
}

const Milestones = ({ data, isLoading, isError, error }: MilestonesProps) => {
  const classes = useStyles();

  const tableContent = () => {
    const caseActions = data?.filter((x) => x.isET);

    if (!caseActions?.length)
      return (
        <TableRow>
          <TableCell className={classes.blankCell} colSpan={3}>
            No milestones to show
          </TableCell>
        </TableRow>
      );

    return caseActions.sort(actionSortFunction).map((a) => (
      <TableRow key={a.id}>
        <TableCell>{a.type}</TableCell>
        <TableCell>{format(new Date(a.due), "dd/MM/yyyy")}</TableCell>
        <TableCell>
          <div className={classes.chipContainer}>
            <Chip
              className={`${classes.statusChip} ${
                classes[getCaseActionStatusClassName(a)]
              }`}
              label={getCaseActionStatusLabel(a)}
            />
          </div>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Card>
      <CardContent>
        {isLoading && (
          <div className={classes.loadingErrorContainer}>
            <CircularProgress />
          </div>
        )}
        {isError && (
          <div className={classes.loadingErrorContainer}>
            <Alert variant="outlined" severity="error">
              {error?.message || "Unable to load milestones"}
            </Alert>
          </div>
        )}
        {!isLoading && !isError && (
          <div>
            <div className={classes.fullGrid}>
              <Typography variant="h6">Milestones</Typography>
            </div>
            <div className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.subtitle}>
                      Milestone
                    </TableCell>
                    <TableCell className={classes.subtitle}>Due date</TableCell>
                    <TableCell className={classes.subtitle}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableContent()}</TableBody>
              </Table>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Milestones;
