import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage } from "../redux/actions/pageActions";

function PageBase({ children }) {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  useEffect(() => {
    dispatch(
      setPage({
        path: match.path,
        url: match.url,
        params: match.params,
      })
    );
  }, [dispatch, match]);

  return children;
}

export default PageBase;
