import React, {useState} from "react";
import { Grid } from "@material-ui/core";
import CasesTableCard from "../components/CasesTableCard";
import { useTracking } from "react-tracking";
import PageBase from "../components/PageBase";
import SeekAdviceFab from "../components/SeekAdviceFab";
import SeekAdviceDialog from "../components/dialogs/SeekAdviceDialog";

function Cases() {
  const { Track } = useTracking({
    page: `Cases`,
  });

  const [seekAdviceOpen, setSeekAdviceOpen] = useState<boolean>(false);

  return (
    <PageBase>
      <Track>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CasesTableCard />
          </Grid>
        </Grid>
        <SeekAdviceFab onClick={() => setSeekAdviceOpen(true)} />
        <SeekAdviceDialog open={seekAdviceOpen} onClose={() => setSeekAdviceOpen(false)} />
      </Track>
    </PageBase>
  );
}

export default Cases;
