import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService, {
  FetchOpenAndEtCaseActionsResponse,
} from "../../services/caseService";

const useCaseActions = (caseId) =>
  useQuery<FetchOpenAndEtCaseActionsResponse[], Error>(
    [queryKeys.caseActions, caseId],
    () => caseService.fetchOpenAndETCaseActions(caseId),
    {
      enabled: !!caseId,
    }
  );

export default useCaseActions;
