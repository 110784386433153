import React from "react";
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => ({
  container: (props: any) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontWeight: theme.typography.fontWeightRegular,
    alignItems: "center",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08);",
    },
    [`&.${props.activeClassName}`]: {
      background: "rgba(0, 0, 0, 0.08);",
    },
  }),
  label: {
    margin: 0,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  labelText: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    opacity: 0.5,
  },
  badge: {
    marginLeft: "auto",
  },
}));

function SidebarCategory({
  name,
  icon: Icon,
  isOpen = false,
  isCollapsable = false,
  ...rest
}) {
  const classes = useStyles({ activeClassName: rest.activeClassName });

  return (
    <ListItem {...rest} className={classes.container}>
      <Icon className={classes.icon} />
      <ListItemText
        className={classes.label}
        classes={{ primary: classes.labelText }}
      >
        {name}
      </ListItemText>
      {isCollapsable ? (
        isOpen ? (
          <ExpandLess className={classes.icon} />
        ) : (
          <ExpandMore className={classes.icon} />
        )
      ) : null}
    </ListItem>
  );
}

export default SidebarCategory;
