import React, { useState, useEffect } from "react";
import CardBase from "./cards/CardBase";
import Avatar from "./Avatar";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import useContactCases from "../hooks/queries/useContactCases";
import chipStyles from "../theme/chips";
import { useHistory } from "react-router-dom";
import { green, orange, purple } from "@material-ui/core/colors";
import { Search as SearchIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import SiteSelect from "./SiteSelect";
import { setCasesTablePage } from "../redux/actions/tablePageActions";
import { tablePage } from "../constants/tablePageConstants";
import { useAuth } from "../contexts/authContext";
import { formatShortDate } from "../utils/dateTimeUtils";
import { RootState } from "../redux/store";

const useStyles = makeStyles(() => ({
  moduleChip: {
    color: "white",
    borderRadius: "4px",
    "&.module-0": {
      backgroundColor: green[400],
    },
    "&.module-1": {
      backgroundColor: purple[400],
    },
    "&.module-2": {
      backgroundColor: orange[400],
    },
  },
  userPhoto: {
    marginRight: "8px",
  },
  userBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  employeeBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "-8px 0",
  },
  tableRow: {
    "&:hover": {
      background: "#e7def6",
      cursor: "pointer",
    },
  },
}));

const tableKey = tablePage.CASES_TABLE;

function CasesTableCard() {
  const classes = useStyles();
  const chipClasses = chipStyles();
  const dispatch = useDispatch();
  const [adviser, setAdviser] = useState("");
  const [employee, setEmployee] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [description, setDescription] = useState("");
  const history = useHistory();
  const { isViewAllCasesAllowedForSites } = useAuth();
  const { selectedSite, selectedAccount, sites } = useSelector(
    (state: RootState) => state.account
  );
  const { casesTable } = useSelector((state: RootState) => state.tablePage);
  const { data, isLoading, error, isFetching } = useContactCases({
    pageSize: casesTable.rowsPerPage,
    pageNum: casesTable.page + 1,
    caseNumberQuery: casesTable.caseNumber,
    adviserQuery: casesTable.adviser,
    employeeQuery: casesTable.employee,
    descriptionQuery: casesTable.description,
    primaryContactIdQuery: casesTable.primaryContact,
    displayAll: casesTable.displayAll,
    showClosed: casesTable.displayClosed,
    siteExternalIds: selectedSite
      ? [selectedSite.externalId]
      : Object.keys(sites),
    caseType: casesTable.caseType,
  });

  useEffect(() => {
    setCaseNumber(casesTable.caseNumber);
    setAdviser(casesTable.adviser);
    setEmployee(casesTable.employee);
    setDescription(casesTable.description);
  }, []);

  const isViewAllAllowed = isViewAllCasesAllowedForSites(
    selectedAccount?.childExternalIds ?? []
  );

  const searchForCases = () => {
    dispatch(
      setCasesTablePage({
        ...casesTable,
        key: tableKey,
        page: 0,
        caseNumber: caseNumber == "" ? "" : caseNumber,
        adviser: adviser == "" ? "" : adviser,
        employee: employee == "" ? "" : employee,
        description: description == "" ? "" : description,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      setCasesTablePage({
        ...casesTable,
        key: tableKey,
        page: newPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setCasesTablePage({
        ...casesTable,
        key: tableKey,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      })
    );
  };

  const handleClick = async (caseId) => {
    if (caseId === "") return;
    history.push(`/case/${caseId}`);
  };

  const handlePrimaryContactChange = (e) => {
    dispatch(
      setCasesTablePage({
        ...casesTable,
        key: tableKey,
        page: 0,
        primaryContact: e.target.value,
      })
    );
  };

  const handleShowAllCasesChange = (e) => {
    dispatch(
      setCasesTablePage({
        ...casesTable,
        key: tableKey,
        page: 0,
        displayAll: e.target.checked,
      })
    );
  };
  const handleShowClosedCasesChange = (e) => {
    dispatch(
      setCasesTablePage({
        ...casesTable,
        key: tableKey,
        page: 0,
        displayClosed: e.target.checked,
      })
    );
  };

  const handleCaseTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      setCasesTablePage({
        ...casesTable,
        key: tableKey,
        page: 0,
        caseType: e.target.value,
      })
    );
  };

  const tableContent = () => {
    if (data && data.count > 0)
      return data.cases.map((row) => {
        return (
          <TableRow
            key={row.caseId}
            className={classes.tableRow}
            onClick={() => handleClick(row.caseId)}
          >
            <TableCell component="th" scope="row">
              {row.caseId}
            </TableCell>
            {casesTable.displayClosed && (
              <TableCell align="center">
                <Chip
                  size="small"
                  className={
                    row.dateClosed ? chipClasses.red : chipClasses.green
                  }
                  label={row.dateClosed ? "Closed" : "Open"}
                />
              </TableCell>
            )}
            <TableCell>{row.description}</TableCell>
            <TableCell>{row.primaryContactName}</TableCell>
            <TableCell>{formatShortDate(row.dateCreated)}</TableCell>
            <TableCell>{formatShortDate(row.lastModifiedDate)}</TableCell>
            <TableCell>
              <Box className={classes.userBox}>
                <Avatar
                  className={classes.userPhoto}
                  alt={row.adviser}
                  size="50px"
                />
                {row.adviser}
              </Box>
            </TableCell>
            <TableCell>{row.caseType}</TableCell>
            <TableCell>
              <Box className={classes.employeeBox}>
                {row.employees.map((e) => (
                  <React.Fragment key={e.employeeId}>
                    <Box className={classes.userBox}>
                      <Avatar
                        className={classes.userPhoto}
                        size="50px"
                        alt={e.name}
                        src={e.photo}
                      />
                      {e.name}
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </TableCell>
            <TableCell>{row.account}</TableCell>
          </TableRow>
        );
      });

    return (
      <TableRow>
        <TableCell colSpan={4}>No current tasks.</TableCell>
      </TableRow>
    );
  };

  return (
    <CardBase
      rightComponent={
        <FormControlLabel
          control={
            <Switch
              checked={casesTable.displayClosed}
              onChange={handleShowClosedCasesChange}
              name="showClosedCases"
              color="primary"
            />
          }
          label="Include Closed Cases"
        />
      }
      title={casesTable.displayAll ? "All Cases" : "My Cases"}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
    >
      <>
        {isViewAllAllowed && (
          <FormControlLabel
            control={
              <Switch
                checked={casesTable.displayAll}
                onChange={handleShowAllCasesChange}
                name="showAllCases"
                color="primary"
              />
            }
            label="Show All Cases"
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel>Case Number</InputLabel>
              <Input
                value={caseNumber}
                onChange={(e) => setCaseNumber(e.target.value)}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Button onClick={() => searchForCases()}>
                      <SearchIcon />{" "}
                    </Button>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel>Adviser</InputLabel>
              <Input
                value={adviser}
                onChange={(e) => setAdviser(e.target.value)}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Button onClick={() => searchForCases()}>
                      <SearchIcon />{" "}
                    </Button>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel>Employee</InputLabel>
              <Input
                value={employee}
                onChange={(e) => setEmployee(e.target.value)}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Button onClick={() => searchForCases()}>
                      <SearchIcon />{" "}
                    </Button>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel>Description</InputLabel>
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Button onClick={() => searchForCases()}>
                      <SearchIcon />{" "}
                    </Button>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel>Primary Contact</InputLabel>
              <Select
                value={casesTable.primaryContact}
                onChange={handlePrimaryContactChange}
              >
                <MenuItem value="0">Any</MenuItem>
                {data &&
                  data.contacts &&
                  data.contacts
                    .sort((a, b) =>
                      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                    .map((u) => (
                      <MenuItem key={u.contactId} value={u.contactId}>
                        {u.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel>Case Type</InputLabel>
              <Select
                value={casesTable.caseType}
                onChange={handleCaseTypeChange}
              >
                <MenuItem value={0}>Any</MenuItem>
                {data &&
                  data.caseTypes &&
                  data.caseTypes
                    .map((x) => (
                      <MenuItem key={x.caseTypeId} value={x.caseTypeId}>
                        {x.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <SiteSelect variant="standard" />
          </Grid>
        </Grid>
      </>
      <TableContainer>
        <Table aria-label="simple dense table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              {casesTable.displayClosed && <TableCell>Status</TableCell>}
              <TableCell>Description</TableCell>
              <TableCell>Primary Contact</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Last Modified</TableCell>
              <TableCell>Adviser</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Employees</TableCell>
              <TableCell>Account</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableContent()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 15, 40]}
        component="div"
        count={data ? data.count : 0}
        rowsPerPage={casesTable.rowsPerPage}
        page={casesTable.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </CardBase>
  );
}

export default CasesTableCard;
