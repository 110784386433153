import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const useRetrieveDocument = ({ fileReference, contentType }) =>
  useQuery(
    [queryKeys.retrieveDocument, fileReference, contentType],

    () => caseService.fetchDocument(fileReference, contentType),
    {
      enabled: !!fileReference,
    }
  );

export default useRetrieveDocument;
