import {consentCookieValues, cookieExpiresAfter} from "../constants/cookieConstants";

const getPardotConsentCookieValue = () => {
  const allCookies = document.cookie.split(";");
  const consentCookie = allCookies.filter(x => x.includes(consentCookieValues.PARDOT));
  if (!consentCookie?.length)
    return null;
  return consentCookie[0].split("=")[1];
}

const setPardotConsentCookie = (value) => {
  document.cookie = `${consentCookieValues.PARDOT}=${value};expires=${cookieExpiresAfter()};path=/`;
}

export {
  getPardotConsentCookieValue,
  setPardotConsentCookie,
}