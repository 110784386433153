import React, { useState, useMemo, ChangeEvent } from "react";
import useCaseActivities from "../hooks/queries/useCaseActivities";
import documentService from "../services/documentService";
import CaseActivityPreviewDialog from "./dialogs/CaseActivityPreviewDialog";
import {
    ActivityEvent,
    Attachment
} from "../services/caseService";
import { FilePreview } from "../pages/Case";
import CaseHistoryComponent from "./CaseHistoryComponent";
import CustomerNoteDialog from "./dialogs/CustomerNoteDialog";
import CaseActivityMenu from "./CaseActivityMenu";
import { VALUES_PER_PAGE } from "../constants/tablePageConstants";
import SeekAdviceDialog from "./dialogs/SeekAdviceDialog";
import { useSnackbar } from "notistack";
import caseService from "../services/caseService";

type CaseHistoryCardProps = {
    caseId: number;
    contractId?: number;
    isUnitisedTime: boolean;
    openFileViewer: (file: FilePreview) => void;
};

const CaseHistoryCard = ({
    caseId,
    isUnitisedTime,
    openFileViewer,
}: CaseHistoryCardProps) => {

    const [pageNumber, setPageNumber] = useState(1);
    const [previewCaseActivity, setPreviewCaseActivity] = useState<ActivityEvent | null>(null);
    const [openNewNoteDialog, setOpenNewNoteDialog] = useState(false);
    const [contextAnchorEl, setContextAnchorEl] = useState<EventTarget & HTMLElement | null>(null);
    const [contextEvent, setContextEvent] = useState<ActivityEvent | null>(null);
    const [seekAdviceOpen, setSeekAdviceOpen] = useState<boolean>(false);

    const { data, isLoading, error, isFetching } = useCaseActivities(caseId);

    const { enqueueSnackbar } = useSnackbar();

    const { caseActivities, photos } = data || {};

    const count = useMemo(() => {
        if (!caseActivities)
            return 0;

        return caseActivities.length;
    }, [caseActivities]);    

    const numberOfPages = useMemo(() => {
        if (count)
            return Math.ceil(count / VALUES_PER_PAGE);
    }, [count]);

    const pagedActivities = useMemo(() => {
        if (!caseActivities)
            return [];

        return caseActivities.slice((pageNumber - 1) * VALUES_PER_PAGE, pageNumber * VALUES_PER_PAGE);
    }, [caseActivities, pageNumber]); 

    const handlePageChange = (event: ChangeEvent, value: number) => {
        setPageNumber(value);
        window.scrollTo(0, 0);
    };

    const handleOpenContextMenu = (e: ActivityEvent, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setContextEvent(e);
        setContextAnchorEl(event.currentTarget);
    };

    const handleContextMenuClose = () => {
        setContextEvent(null);
        setContextAnchorEl(null);
    };

    const handleEditNoteClick = () => {
        setOpenNewNoteDialog(true);
        setContextAnchorEl(null);
    };

    const handleCloseCustomerNoteDialog = () => {
        setOpenNewNoteDialog(false);
        setContextEvent(null);
    }
    const handleClickAttachment = async (file: Attachment) => {
        const { reference, filename, contentType, extension } = file;

        try {
            const previewPath = await documentService.retrieveDocumentPreviewPath({
                reference,
                contentType,
                filename
            });

            console.log({previewPath});

            if (previewPath === null) {
                enqueueSnackbar("File may be password protected - downloading instead", { variant: "warning" });

                const response = await caseService.fetchDocument(reference, "arraybuffer");
                let blob = new Blob([response], { type: extension });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                return;
            }

            openFileViewer({
                type: extension,
                path: previewPath,
                name: filename,
                azureGuid: reference,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const handlePreviewClick = (caseEvent: ActivityEvent) =>
        setPreviewCaseActivity(caseEvent);

    const handlePreviewClose = () => setPreviewCaseActivity(null);

    return (
        <>
            <CaseHistoryComponent
                photos={photos || {}}
                caseActivities={pagedActivities}
                count={count}
                valuesPerPage={VALUES_PER_PAGE}
                numberOfPages={numberOfPages}
                pageNumber={pageNumber}
                isLoading={isLoading}
                isUnitisedTime={isUnitisedTime}
                error={error}
                onPreviewClick={handlePreviewClick}
                onPageChange={handlePageChange}
                onNewNoteClick={setOpenNewNoteDialog}
                onSideMenuClick={handleOpenContextMenu}
                isFetching={isFetching}
                setSeekAdviceOpen={setSeekAdviceOpen}
            />
            <CaseActivityMenu
                contextEvent={contextEvent}
                contextAnchorEl={contextAnchorEl}
                onContextMenuClose={handleContextMenuClose}
                onEditClick={handleEditNoteClick}
            />
            {
                !!previewCaseActivity && 
                <CaseActivityPreviewDialog
                    open={!!previewCaseActivity}
                    caseActivity={previewCaseActivity}
                    onClose={handlePreviewClose}
                    openDocument={handleClickAttachment}
                />
            }
            {
                openNewNoteDialog &&
                <CustomerNoteDialog
                    open={openNewNoteDialog}
                    existingSubject={contextEvent?.activityDescription}
                    existingMessage={contextEvent?.content}
                    caseActivityId={contextEvent?.caseActivityId}
                    attachments={contextEvent?.attachments}
                    onClose={handleCloseCustomerNoteDialog}
                    pageNumber={pageNumber}
                    caseId={caseId}
                />
            }
            {
                seekAdviceOpen &&
                <SeekAdviceDialog
                open={seekAdviceOpen}
                onClose={() => setSeekAdviceOpen(false)}
                caseId={caseId}
                />
            }
        </>
    );
};

export default CaseHistoryCard;
