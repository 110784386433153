export const tutorialAppearanceModes = {
  FIRST_LOGIN: 1,
  EVERY_TIME: 2,
};

export const tutorialAppearanceModesList = [
  {
    label: "First login",
    value: tutorialAppearanceModes.FIRST_LOGIN,
  },
  {
    label: "Every time",
    value: tutorialAppearanceModes.EVERY_TIME,
  },
];
