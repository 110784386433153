import {
  SET_CASES_TABLE_PAGE,
  SET_CONTACT_CASES_TABLE_PAGE,
  SET_TABLE_PAGE,
} from "../types";

type BaseTableState = {
  page: number;
  rowsPerPage: number;
  displayAll: boolean;
};

type TablePageState = {
  casesTable: BaseTableState & {
    displayClosed: boolean;
    caseNumber: string;
    adviser: string;
    employee: string;
    description: string;
    primaryContact: number;
    caseType: number;
  };
  contactCasesTable: BaseTableState;
};

const initialState: TablePageState = {
  casesTable: {
    page: 0,
    rowsPerPage: 15,
    displayAll: false,
    displayClosed: false,
    caseNumber: "",
    adviser: "",
    employee: "",
    description: "",
    primaryContact: 0,
    caseType: 0,
  },
  contactCasesTable: {
    page: 0,
    rowsPerPage: 5,
    displayAll: false,
  },
};
function tablePageReducer(state = initialState, action): TablePageState {
  switch (action.type) {
    case SET_TABLE_PAGE:
      return {
        ...state,
        [action.payload.key]: {
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      };
    case SET_CASES_TABLE_PAGE:
      return {
        ...state,
        [action.payload.key]: {
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
          displayAll: action.payload.displayAll,
          displayClosed: action.payload.displayClosed,
          caseNumber: action.payload.caseNumber,
          adviser: action.payload.adviser,
          employee: action.payload.employee,
          description: action.payload.description,
          primaryContact: action.payload.primaryContact,
          caseType: action.payload.caseType,
        },
      };
    case SET_CONTACT_CASES_TABLE_PAGE:
      return {
        ...state,
        [action.payload.key]: {
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
          displayAll: action.payload.displayAll,
        },
      };
    default:
      return state;
  }
}

export default tablePageReducer;
