import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  CircularProgress,
  Box,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  DialogContentText,
  makeStyles,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useUserProfile from "../hooks/queries/useUserProfile";
import { useAuth } from "../contexts/authContext";
import Avatar from "./Avatar";
import callbackService from "../services/callbackService";
import { useSelector } from "react-redux";
import useIsMobile from "../hooks/useIsMobile";
import { RootState } from "../redux/store";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const RequestCallbackDialog = ({
  isOpen,
  setIsOpen,
  advisers,
  selectedAdviserId,
}: any) => {
  const classes = useStyles();
  const { data, isLoading, isError, error } = useUserProfile(isOpen);
  const initialPhone = data?.phone;

  const { user } = useAuth();

  const { selectedSite, selectedAccount } = useSelector(
    (state: RootState) => state.account
  );

  const [important, setImportant] = useState(false);
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [adviserId, setAdviserId] = useState("");
  const [saving, setSaving] = useState(false);

  const isMobile = useIsMobile();

  const accountExternalId = selectedSite
    ? selectedSite.externalId
    : selectedAccount?.account.externalId ?? "";

  const handleClose = () => {
    setImportant(false);
    setNote("");
    setPhone(initialPhone || "");
    setAdviserId("");
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) return;

    try {
      setSaving(true);
      await callbackService.requestCallback({
        accountExternalId,
        name: `${user.details.firstName} ${user.details.lastName}`,
        phone,
        important,
        note,
        userIds: advisers?.map((a) => a.userId),
        requestedAdviser: advisers?.filter((a) => a.userId === adviserId)[0]
          ?.name,
      });
      handleClose();
      // TODO - display snack
    } catch (e) {
      // TODO - display snack
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (initialPhone) setPhone(initialPhone);
  }, [initialPhone]);

  useEffect(() => {
    if (selectedAdviserId) setAdviserId(selectedAdviserId);
  }, [selectedAdviserId]);

  const getDialogContent = () => {
    if (isLoading)
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      );

    if (isError)
      return (
        <Box display="flex" justifyContent="center">
          <Alert variant="outlined" severity="error">
            {error.message || "Failed to load user profile"}
          </Alert>
        </Box>
          );

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact Name"
              fullWidth
              value={
                user
                  ? `${user.details.firstName} ${user.details.lastName}`
                  : "N/A"
              }
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-name"
              label="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DialogContentText>
              If callback is for a specific adviser please select here:
            </DialogContentText>
          </Grid>
          <Grid item xs={12} sm={6}>
            {isMobile ? (
              <Select
                native
                value={adviserId}
                onChange={(e: any) => setAdviserId(e.target.value)}
              >
                <option value="">Any</option>
                {advisers &&
                  advisers.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
              </Select>
            ) : (
              <Select
                value={adviserId}
                onChange={(e: any) => setAdviserId(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Any</MenuItem>
                {advisers &&
                  advisers.map((a) => (
                    <MenuItem key={a.id} value={a.id}>
                      <Box display="flex" alignItems="center">
                        <Avatar
                          className={classes.avatar}
                          size="50px"
                          alt={a.name}
                          src={a.photo}
                        />
                        {a.name}
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={isMobile}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box>Request a callback</Box>
          {!isLoading && !isError ? (
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={important}
                    onChange={(e) => setImportant(e.target.checked)}
                  />
                }
                label="Important"
              />
            </Box>
          ) : (
            <div></div>
          )}
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <Box m={3}>{getDialogContent()}</Box>
        <DialogActions>
          {saving ? (
            <CircularProgress />
          ) : (
            <>
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" color="primary">
                Request callback
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RequestCallbackDialog;
