import React from "react";
import Avatar from "./Avatar";
import {
  Badge,
  Box,
  Chip,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { differenceInMonths } from "date-fns";
import chipStyles from "../theme/chips";
import CardBase from "./cards/CardBase";

const useStyles = makeStyles(() => ({
  userPhoto: {
    marginRight: "8px",
  },
  badge: {
    whiteSpace: "nowrap",
    cursor: "default",
  },
  employeeBox: {
    textAlign: "center",
  },
  linkedCases: {
    color: red[800],
  },
}));

const weeksOfService = (employmentStartDate) => {
  if (employmentStartDate == null) return 200; // wtf lol

  const today = new Date();
  const startDate = new Date(employmentStartDate);

  return (today.valueOf() - startDate.valueOf()) / (1000 * 60 * 60 * 24 * 7);
};

const formatLengthOfService = (employmentStartDate) => {
  if (!employmentStartDate) return "Unknown";

  const today = new Date();
  const startDate = new Date(employmentStartDate);

  const monthsOfService = differenceInMonths(
    new Date(today),
    new Date(startDate)
  );

  const years = Math.floor(monthsOfService / 12);
  const months = monthsOfService % 12;

  let str = "";

  if (years === 0) {
    if (months === 0) {
      return "< 1 month";
    }
    if (months === 1) {
      return "1 month";
    }
    return `${months} months`;
  }
  if (years > 0) {
    str = str + years + " year";
  }
  if (years > 1) {
    str += "s";
  }
  if (months > 0) {
    str = str + ", " + months + " month";
  }
  if (months > 1) {
    str += "s";
  }
  return str;
};

const EmployeeCard = ({ employees }) => {
  const classes = useStyles();
  const chipClasses = chipStyles();

  const MapEmployee = (e) => (
    <Box
      key={e.employeeId}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={5}
      mb={2}
      ml={2}
      mr={2}
      className={classes.employeeBox}
    >
      <Box>
        <Tooltip
          title={
            e.protectedCharacteristics ? (
              <ul>
                {e.protectedCharacteristics.split(",").map((c, index) => (
                  <li key={index}>{c}</li>
                ))}
              </ul>
            ) : (
              ""
            )
          }
          placement="bottom"
        >
          <Badge
            className={classes.badge}
            badgeContent={e.protectedCharacteristics ? "High Risk" : 0}
            color="error"
          >
            <Avatar
              alt={e.name}
              size="50px"
              src={e.photo}
              className={classes.userPhoto}
            />
          </Badge>
        </Tooltip>
      </Box>
      <Box>
        <Typography variant="subtitle2">{e.name}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2">{e.position}</Typography>
      </Box>
      <Box mt={1}>
        <Tooltip title="Length Of Service">
          <Chip
            className={
              weeksOfService(e.employmentStartDate) < 103
                ? chipClasses.green
                : chipClasses.amber
            }
            label={formatLengthOfService(e.employmentStartDate)}
          />
        </Tooltip>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="24px"
      >
        <Box>
          <Typography variant="body2">
            Linked Cases:{" "}
            <span className={classes.linkedCases}>{e.linkedCasesCount}</span>
          </Typography>
        </Box>
        <Box width="30px">
          <span></span>
        </Box>
      </Box>
    </Box>
  );

  return (
    <CardBase title="Employees">
      {employees.map((e) => MapEmployee(e))}
    </CardBase>
  );
};

export default EmployeeCard;
