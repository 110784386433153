import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService, {
  FetchETCaseDetailsResponse,
} from "../../services/caseService";

const useETCaseDetails = (caseId) =>
  useQuery<FetchETCaseDetailsResponse, Error>(
    [queryKeys.eTCaseDetails, caseId],
    () => caseService.fetchETCaseDetails(caseId),
    {
      enabled: !!caseId,
    }
  );

export default useETCaseDetails;
