import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import LoginLoader from "../components/LoginLoader";
import { setAuthHeader } from "../utils/axios";
import jwtDecode from "jwt-decode";
import { salesforceRoles } from "../constants/roleConstants";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  selectAccount,
  setRootAccounts,
} from "../redux/actions/accountActions";
import accountService from "../services/accountService";
import IsWorkNestTenant from "../utils/isWorkNestTenant";
import { differenceInHours } from "date-fns";
import trackingService from "../services/trackingService";

type AuthContext = {
  isLoading: boolean;
  user: User | null;
  logout: () => Promise<void>;
  isWorknestUser: boolean;
  endImpersonationSession: () => Promise<void>;
  impersonatedUser: ImpersonatedUserDetails | null;
  isViewAllCasesAllowedForSites: (siteIds: string[]) => boolean;
};

const defaultContext: AuthContext = {
  isLoading: true,
  user: null,
  logout: async () => {},
  isWorknestUser: false,
  endImpersonationSession: async () => {},
  impersonatedUser: null,
  isViewAllCasesAllowedForSites: (siteIds: string[]) => false,
};

export const MsalContext = createContext<AuthContext>(defaultContext);

const workNestTenantId = process.env.REACT_APP_WORKNEST_TENANT_ID;
const REDIRECT_URL_KEY = "redirect_url";
const WORKNEST_TOKEN_KEY = "worknest_token";
const appEnvironment = process.env.REACT_APP_ENV;

export const IMPERSONATION_ID_KEY = "impersonation-id";
export const IMPERSONATION_TOKEN_KEY = "impersonation-token";

const tokenIsUsable = (tokenExpiry: number) => {
  const expirationTime = new Date(tokenExpiry * 1000);
  const hoursLeft = differenceInHours(expirationTime, new Date());
  return hoursLeft > 3;
};

const getCookieByName = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}-${appEnvironment}=`);
  if (parts?.length === 2) return parts.pop()?.split(";").shift();
};

const deleteCookieByName = (name: string) => {
  document.cookie = `${name}-${appEnvironment}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const convertToArray = (item: string | string[]) =>
  typeof item === "string" ? [item] : item;

type AuthenticationMethod = "microsoft" | "google";

type WorkNestToken = {
  email: string;
  given_name: string;
  family_name: string;
  accounts: string[];
  apps: string | string[];
  authentication_method: AuthenticationMethod;
  sub: string;
  roles: string | string[];
  tid: string;
  exp: number;
};

type UserDetails = {
  email: string;
  firstName: string;
  lastName: string;
  accounts: string[];
  apps: string[];
  authMethod: AuthenticationMethod;
  userId: string;
  roles: string[];
  tid: string;
};

type User = {
  details: UserDetails;
  token: string;
};

type ImpersonatedUserDetails = {
  name: string;
  email: string;
};

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [impersonatedUser, setImpersonatedUser] =
    useState<ImpersonatedUserDetails | null>(null);
  const [impersonationSessionId, setImpersonationSessionId] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (!localStorage.getItem(REDIRECT_URL_KEY) && location.pathname !== "/")
      localStorage.setItem(REDIRECT_URL_KEY, location.pathname);
  }, []);

  const handleInternalUserLogin = useCallback(() => {
    let redirectUrl = "/error?type=roles";

    history.push(redirectUrl);
  }, []);

  const handleClientLogin = useCallback(async () => {
    try {
      const rootAccounts = await accountService.fetchAccountsDetailsForUser();
      dispatch(setRootAccounts(rootAccounts));
      if (rootAccounts.length === 1) {
        dispatch(selectAccount(rootAccounts[0]));
        const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
        if (redirectUrl) {
          localStorage.removeItem(REDIRECT_URL_KEY);
          history.push(redirectUrl);
        }
      } else {
        history.push("/account-select");
      }
    } catch (e: any) {
      throw e;
    }
  }, []);

  const initUserDetails = useCallback((worknestToken) => {
    const decoded = jwtDecode<WorkNestToken>(worknestToken);

    const details: UserDetails = {
      email: decoded.email,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      accounts: convertToArray(decoded.accounts),
      apps: convertToArray(decoded.apps),
      authMethod: decoded.authentication_method,
      userId: decoded.sub,
      roles: convertToArray(decoded.roles),
      tid: decoded.tid,
    };

    const userObject = {
      details,
      token: worknestToken,
    };
    setUser(userObject);
    setAuthHeader(worknestToken);

    return details;
  }, []);

  const initialiseWorknestUserSession = useCallback(
    (worknestToken) => {
      return new Promise(async (resolve, reject) => {
        try {
          const { tid } = initUserDetails(worknestToken);

          if (IsWorkNestTenant(tid)) {
            handleInternalUserLogin();
            resolve(null);
            return;
          }

          await handleClientLogin();
        } catch (e: any) {
          reject(e);
        } finally {
          setIsLoading(false);
        }
      });
    },
    [initUserDetails, handleInternalUserLogin, handleClientLogin],
  );

  function redirectToMyWorkNest({
    withReturnRedirect = false,
    isLogout = false,
  }: {
    withReturnRedirect?: boolean;
    isLogout?: boolean;
  }) {
    const redirectUrl = new URL(process.env.REACT_APP_MYWORKNEST_APP_URL);

    if (withReturnRedirect)
      redirectUrl.searchParams.append("appRedirect", window.location.href);
    else if (isLogout)
      redirectUrl.searchParams.append("isLogout", true.toString());

    window.location.href = redirectUrl.href;
  }

  useEffect(() => {
    async function handleSessionInit() {
      try {
        const impersonationTokenCookie = getCookieByName(
          IMPERSONATION_TOKEN_KEY,
        );
        const impersonationIdCookie = getCookieByName(IMPERSONATION_ID_KEY);

        if (impersonationTokenCookie && impersonationIdCookie) {
          const { given_name, family_name, email, exp } =
            jwtDecode<WorkNestToken>(impersonationTokenCookie);
          if (tokenIsUsable(exp)) {
            await setImpersonationSessionDetails({
              sessionId: parseInt(impersonationIdCookie),
              userDetails: { name: `${given_name} ${family_name}`, email },
              token: impersonationTokenCookie,
            });
            return;
          }
        }

        const workNestTokenFromStorage = getCookieByName(WORKNEST_TOKEN_KEY);
        if (!workNestTokenFromStorage) {
          redirectToMyWorkNest({ withReturnRedirect: true });
          return;
        }

        const decoded = jwtDecode<WorkNestToken>(workNestTokenFromStorage);
        const hoursToExpiry = differenceInHours(
          new Date(decoded.exp * 1000),
          new Date(),
        );

        if (hoursToExpiry < 3) {
          redirectToMyWorkNest({ withReturnRedirect: true });
          return;
        }

        await initialiseWorknestUserSession(workNestTokenFromStorage);
      } catch (error) {
        console.error(error);
        redirectToMyWorkNest({ withReturnRedirect: false });
      }
    }

    handleSessionInit();
  }, [initialiseWorknestUserSession]);

  const logout = async () => {
    if (impersonationSessionId) {
      await endImpersonationSession();
      return;
    }
    deleteCookieByName(WORKNEST_TOKEN_KEY);
    redirectToMyWorkNest({ isLogout: true });
  };

  const setImpersonationSessionDetails = async ({
    sessionId,
    userDetails,
    token,
  }: {
    sessionId: number;
    userDetails: ImpersonatedUserDetails;
    token: string;
  }) => {
    setImpersonationSessionId(sessionId);
    setImpersonatedUser(userDetails);

    await initialiseWorknestUserSession(token);
  };

  const endImpersonationSession = async () => {
    try {
      deleteCookieByName(IMPERSONATION_ID_KEY);
      deleteCookieByName(IMPERSONATION_TOKEN_KEY);
      if (!impersonationSessionId) return;
      await trackingService.endImpersonationSession(impersonationSessionId);
    } catch (e: any) {
      console.error(e);
      throw new Error("Could not end impersonation session properly.");
    } finally {
      redirectToMyWorkNest({ withReturnRedirect: false });
    }
  };

  const hasRole = (role) => {
    if (!user?.details?.roles) return false;
    return user.details.roles.includes(role);
  };

  const isWorknestUser = user?.details.tid === workNestTenantId;

  const isViewAllCasesAllowedForSites = (siteIds: string[] = []) => {
    return siteIds.some((siteId) =>
      hasRole(`${salesforceRoles.CASENEST_ALLCASES}:${siteId}`),
    );
  };

  if (isLoading) return <LoginLoader />;

  const contextValue = {
    isLoading,
    user,
    logout,
    endImpersonationSession,
    impersonatedUser,
    isViewAllCasesAllowedForSites,
    isWorknestUser,
  };

  return (
    <MsalContext.Provider value={contextValue}>{children}</MsalContext.Provider>
  );
};

const useAuth = () => {
  const authContext = useContext(MsalContext);

  if (!authContext) {
    throw new Error("useAuth has to be used within <MsalContext.Provider>");
  }

  return authContext;
};

export { AuthProvider, useAuth };
