const discreteDateFilters = {
  MONTH: "1",
  TWELVE_MONTHS: "12",
  TWENTY_FOUR_MONTHS: "24",
  THIRTY_SIX_MONTHS: "36",
}

export const discreteDateFilterList = [
  {
    value: discreteDateFilters.MONTH,
    label: "Past Month",
  },
  {
    value: discreteDateFilters.TWELVE_MONTHS,
    label: "Past 12 Months",
  },
  {
    value: discreteDateFilters.TWENTY_FOUR_MONTHS,
    label: "Past 24 Month",
  },
  {
    value: discreteDateFilters.THIRTY_SIX_MONTHS,
    label: "Past 36 Months",
  },
]