import axios from "../utils/axios";
import stringUtils from "../utils/stringUtils";

const accountEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/Account`;

export type Account = {
  id: number;
  parentId?: number;
  parentExternalId?: string;
  externalId: string;
  name: string;
  addressStreet?: string;
  addressCity?: string;
  addressCounty?: string;
  addressPostCode?: string;
  isActive: boolean;
  latitude?: number;
  longitude?: number;
  legacyBlobId?: string;
};

export type RootAccount = {
  account: Account;
  childExternalIds: string[];
};

const fetchAccountsDetailsForUser = () =>
  axios.get<never, RootAccount[]>(`${accountEndpointUrl}/GetForUser`);

const fetchAccountsForApp = ({ accountExternalIds }) => {
  const queryString = stringUtils.buildQueryStringList(
    "externalIds",
    accountExternalIds
  );
  return axios.get<never, Account[]>(
    `${accountEndpointUrl}/App/${process.env.REACT_APP_CASENEST_APP_ID}/${queryString}`
  );
};

const accountService = {
  fetchAccountsDetailsForUser,
  fetchAccountsForApp,
};

export default accountService;
