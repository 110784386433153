import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const useContactCases = ({
  caseNumberQuery,
  adviserQuery,
  employeeQuery,
  descriptionQuery,
  pageSize,
  pageNum,
  primaryContactIdQuery,
  displayAll,
  showClosed,
  siteExternalIds,
  caseType,
}: any) =>
  useQuery(
    [
      queryKeys.contactCases,
      caseNumberQuery,
      adviserQuery,
      employeeQuery,
      descriptionQuery,
      pageSize,
      pageNum,
      primaryContactIdQuery,
      displayAll,
      showClosed,
      siteExternalIds?.join(","),
      caseType,
    ],

    () =>
      caseService.fetchCasesForContact(
        caseNumberQuery,
        adviserQuery,
        employeeQuery,
        descriptionQuery,
        pageSize,
        pageNum,
        primaryContactIdQuery,
        displayAll,
        showClosed,
        siteExternalIds,
        caseType,
      ),
    {
      enabled:
        !!siteExternalIds?.length &&
        (!!adviserQuery ||
          !!caseNumberQuery ||
          !!employeeQuery ||
          !!descriptionQuery ||
          !!pageSize ||
          !!pageNum ||
          !!primaryContactIdQuery ||
          !!displayAll ||
          !!showClosed ||
          !!caseType),
    }
  );

export default useContactCases;
