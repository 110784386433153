import clsx from "clsx";
import { Divider, Drawer, IconButton, makeStyles } from "@material-ui/core";
import DrawerMenuItems from "./DrawerMenuItems";
import React from "react";
import { useAuth } from "../../contexts/authContext";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import layoutConstants from "../../constants/layoutConstants";
import useIsMobile from "../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: layoutConstants.drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: layoutConstants.drawerWidth,
    borderRight: "none",
    backgroundColor: theme.palette.primary.dark,
  },
  impersonationShift: {
    marginTop: layoutConstants.impersonationBannerHeight,
  },
  mobileSiteSelectContainer: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    padding: theme.spacing(2),
  },
  mobileSiteSelectFormControl: {
    width: "100%",
    color: "inherit",
    "& .MuiFormLabel-root": {
      color: "inherit",
    },
    "& .MuiSelect-icon": {
      color: "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "inherit",
      opacity: 0.5,
    },
    "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "inherit",
      opacity: 1,
    },
    "& .MuiInputBase-root": {
      color: "inherit",
    },
    "& option": {
      color: "black",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    backgroundColor: theme.palette.primary.main,
  },
  closeMenuIcon: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

const container = window !== undefined ? () => window.document.body : undefined;

function NavigationDrawer({ isOpen, onToggleOpen }) {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const { impersonatedUser } = useAuth();

  return isMobile ? (
    <Drawer
      className={classes.drawer}
      variant={"temporary"}
      anchor="left"
      container={container}
      open={isOpen}
      onClose={onToggleOpen}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.impersonationShift]: !!impersonatedUser,
        }),
      }}
    >
      <DrawerMenuItems />
    </Drawer>
  ) : (
    <Drawer
      className={classes.drawer}
      variant={"persistent"}
      anchor="left"
      open={isOpen}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.impersonationShift]: !!impersonatedUser,
        }),
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={onToggleOpen} className={classes.closeMenuIcon}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <DrawerMenuItems />
    </Drawer>
  );
}

export default NavigationDrawer;
