import React from "react";
import { Avatar as MuiAvatar, makeStyles } from "@material-ui/core";

type AvatarProps = {
  size?: "50px" | "100px" | "150px";
  [key: string]: any;
};

const useStyles = makeStyles(() => ({
  avatar: (props: AvatarProps) => ({
    height: props.size,
    width: props.size,
  }),
}));

const Avatar = ({ size, ...props }: AvatarProps) => {
  if (!size) return <MuiAvatar {...props} />;

  const classes = useStyles({ size });

  return <MuiAvatar className={classes.avatar} {...props} />;
};

export default Avatar;
