const EMAIL = "Email";
const NOTE = "Note";
const CALL_WITH_NOTE = "Call with Note";
const INTERNAL_NOTE = "Internal Note";
const CALL = "Call";
const MISC = "Misc";
const DOCUMENT = "Document";
const ADVISER = "Adviser";
const DRAFTING = "Drafting";
const OUTCOME = "Outcome";
const MEETING = "Meeting";

export {
    EMAIL,
    NOTE,
    CALL_WITH_NOTE,
    INTERNAL_NOTE,
    CALL,
    MISC,
    DOCUMENT,
    ADVISER,
    DRAFTING,
    OUTCOME,
    MEETING
};
