import{useState} from "react";
import {
    alpha,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import {AttachFile, InfoOutlined} from "@material-ui/icons";
import {useSnackbar} from "notistack";
import {Attachment} from "../../services/caseService";
import {red} from "@material-ui/core/colors";
import useAddCustomerNote from "../../hooks/mutations/useAddCustomerNote";
import useUpdateCustomerNote from "../../hooks/mutations/useUpdateCustomerNote";
import AttachmentsContainer from "../AttachmentsContainer";
import { useFileUploads } from "../../contexts/fileUploadContext";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "initial",
            },
        },
        title: { fontSize: 18, fontWeight: 600 },
        formItem: {
            margin: theme.spacing(1, 0),
        },
        actionContainer: {
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(2, 3),
        },
        actionBtns: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        infoContainer: {
            display: "flex",
            alignItems: "start",
            fontSize: 13,
            color: alpha(theme.palette['errorRed'], 0.75),
        },
        infoIcon: {
            marginRight: theme.spacing(1),
            fontSize: 16,
        },
        saveBtn: {
            whiteSpace: "nowrap",
            padding: theme.spacing(1, 3),
            marginLeft: theme.spacing(2),
        },
        iconButton: {
            padding: theme.spacing(1),
            color: theme.palette.primary.main,
            fontSize: 40,
        },
        discardButton: {
            color: red[900],
        },
    };
});

type CustomerNoteDialogProps = {
    open: boolean;
    existingSubject?: string;
    existingMessage?: string;
    caseActivityId?: number;
    attachments?: Attachment[];
    onClose: () => void;
    pageNumber: number;
    caseId: number;
};

const CustomerNoteDialog = ({
    open,
    existingSubject,
    existingMessage,
    caseActivityId,
    attachments,
    onClose,
    pageNumber,
    caseId,
}: CustomerNoteDialogProps) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [subject, setSubject] = useState(existingSubject ?? "");
    const [message, setMessage] = useState(existingMessage ?? "");
    const [existingAttachments, setExistingAttachments] = useState(
        attachments ?? []
    );
    const [existingFilesToDelete, setExistingFilesToDelete] = useState<string[]>(
        []
    );
    const [formErrors, setFormErrors] = useState({
        subject: false,
        message: false,
    });
    const [discardChangesOpen, setDiscardChangesOpen] = useState<boolean>(false);
    const {
        uploads,
        setUploads,
        setFailedUploads,
        handleFileUpload,
        handleNewFileDelete,
        uploadInProgress
    } = useFileUploads();
    const addCustomerNote = useAddCustomerNote();
    const updateCustomerNote = useUpdateCustomerNote();

    const handleFormReset = () => {
        setSubject("");
        setMessage("");
        setFormErrors({
            subject: false,
            message: false,
        });
        setUploads([]);
        setFailedUploads([]);
        setExistingAttachments([]);
    };

    const handleFormValidation = () => {
        let formIsValid = true;
        let errors = { ...formErrors };
        if (subject === "") {
            formIsValid = false;
            errors = { ...errors, subject: true };
        }

        if (message === "") {
            formIsValid = false;
            errors = { ...errors, message: true };
        }

        if (!formIsValid) {
            setFormErrors({ ...errors });
        }
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleFormValidation()) {
            enqueueSnackbar("Please ensure all required form fields have been filled in.", { variant: "error" });
            return;
        }
        if (caseActivityId)
            updateCustomerNote.mutate({ caseId, caseActivityId, subject, message, newAttachments: uploads, filesToDelete: existingFilesToDelete, pageNumber });
        else
            addCustomerNote.mutate({ caseId, subject, message, attachments: uploads })
        
        handleFormReset();
        onClose();
    };

    const handleExistingFileDelete = (fileReference: string) => {
        setExistingFilesToDelete([...existingFilesToDelete, fileReference]);
        setExistingAttachments(
            existingAttachments.filter(
                (attachment) => attachment.reference !== fileReference
            )
        );
    };
    
    const handleClickCancel = () => {
        if (uploads.length > 0 || subject.length > 0 || message.length > 0) {
            setDiscardChangesOpen(true);
            return;
        }
        onClose();
    }
    
    const handleCloseModal = async (event?: object, reason?: string) => {
        if (reason === "backdropClick")
            return;

        for (const file of uploads)
            await handleNewFileDelete(file.id);

        onClose();
    }
        

    return (
        <Dialog className={classes.root} onClose={handleCloseModal} open={open} disableEscapeKeyDown>
            {discardChangesOpen ?
                <>
                    <DialogTitle>Discard Changes</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Any changes will be lost.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDiscardChangesOpen(false)} color="primary">Cancel</Button>
                        <Button onClick={handleCloseModal} className={classes.discardButton}>Discard</Button>
                    </DialogActions>
                </> :
                <form onSubmit={handleSubmit} noValidate>
                    <DialogTitle>
                        <Typography variant="body1" color="primary" className={classes.title}>
                            New Note
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            className={classes.formItem}
                            fullWidth
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            label="Subject"
                            multiline
                            variant="outlined"
                            inputProps={{ maxLength: 255 }}
                            error={formErrors.subject && !subject}
                            helperText={formErrors.subject && !subject ? "Please enter a subject" : ""}
                            required
                        />
                        <TextField
                            className={classes.formItem}
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            label="Note"
                            multiline
                            minRows={8}
                            variant="outlined"
                            error={formErrors.message && !message}
                            helperText={formErrors.message && !message ? "Please enter a message" : ""}
                            required
                        />
                        <AttachmentsContainer existingAttachments={existingAttachments} handleExistingFileDelete={handleExistingFileDelete} />
                    </DialogContent>
                    <DialogActions className={classes.actionContainer}>
                        <div className={classes.infoContainer}>
                            <InfoOutlined fontSize="inherit" className={classes.infoIcon} />
                            <Typography variant="inherit">
                                Your legal adviser will not be notified when a note is added.
                                'Seek Advice' to request a review.
                            </Typography>
                        </div>
                        <div className={classes.actionBtns}>
                            <IconButton
                                color="primary"
                                component="label"
                                onChange={handleFileUpload}
                            >
                                <input type="file" multiple hidden />
                                <AttachFile />
                            </IconButton>
                            <Button
                                color="secondary"
                                onClick={handleClickCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={uploadInProgress}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                className={classes.saveBtn}
                            >
                                Save Note
                            </Button>
                        </div>
                    </DialogActions>
                </form>}
        </Dialog>
    );
};

export default CustomerNoteDialog;
