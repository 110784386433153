import trackingService from "./services/trackingService";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Switch } from "react-router-dom";
import { routes } from "./routes";
import { useTracking } from "react-tracking";
import AuthLayout from "./layouts/AuthLayout";
import { SnackbarProvider } from "notistack";
import TutorialTracker from "./components/TutorialTracker";
import CookieJar from "./components/CookieJar";
import RouteMapper from "./components/RouteMapper";
import {FileUploadProvider} from "./contexts/fileUploadContext";

function App() {
  const { Track } = useTracking(
    { app: "casenest" },
    {
      dispatch: trackingService.sendTrackingData,
      process: (ownTrackingData) =>
        ownTrackingData.page ? { event: "pageView" } : null,
    }
  );

  return (
    <Track>
      <AuthLayout>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider maxSnack={3}>
              <FileUploadProvider>
                <TutorialTracker />
                <CookieJar />
                <Switch>
                  <RouteMapper routes={routes} />
                </Switch>
              </FileUploadProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </AuthLayout>
    </Track>
  );
}

export default App;
