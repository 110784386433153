import { format } from "date-fns";

export const formatTime = (s) => {
  let timeString = "";
  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s - hours * 3600) / 60);
  const seconds = Math.floor(s - hours * 3600 - minutes * 60);

  timeString = hours ? timeString + hours + "h " : timeString;
  timeString = minutes ? timeString + minutes + "m " : timeString;
  timeString = seconds ? timeString + seconds + "s" : timeString;

  return timeString;
};

export const formatDigitalTime = (s) => {
  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s - hours * 3600) / 60);
  const seconds = Math.floor(s - hours * 3600 - minutes * 60);

  const hoursString = hours < 10 ? "0" + hours.toString() : hours.toString();
  const minutesString =
    minutes < 10 ? "0" + minutes.toString() : minutes.toString();
  const secondsString =
    seconds < 10 ? "0" + seconds.toString() : seconds.toString();

  return `${hoursString}:${minutesString}:${secondsString}`;
};

export const formatShortTime = (s) => {
  let timeString = "";
  let hours = Math.floor(s / 3600);
  let minutes = Math.floor((s - hours * 3600) / 60);

  timeString = hours ? timeString + hours + "h " : timeString;
  timeString = minutes ? timeString + minutes + "m " : timeString;

  return timeString;
};

export const formatShortDate = (dateString, options = {}) =>
  format(new Date(dateString), "dd/MM/yyyy", options);

export const formatISODateTime = (dateString) =>
  format(new Date(dateString), "yyyy-MM-dd'T'HH:mm:ss");

export const formatShortMonthDate = (dateString) =>
  format(new Date(dateString), "dd MMM yyyy");

export const formatShortMonthDateAndTime = (dateString) =>
  format(new Date(dateString), "dd MMM yyyy HH:mm");
