import React, {useState} from "react";
import {Grid, makeStyles} from "@material-ui/core";
import { useTracking } from "react-tracking";
import DashboardHeadlines from "../components/DashboardHeadlines";
import DashboardFilter from "../components/DashboardFilter";
import { DashboardProvider } from "../contexts/dashboardContext";
import ContactCasesTableCard from "../components/ContactCasesTableCard";
import TimeSummaryCard from "../components/TimeSummaryCard";
import TimeSummaryChartCard from "../components/TimeSummaryChartCard";
import AccountDetailsCard from "../components/AccountDetailsCard";
import ELTeamCard from "../components/ELTeamCard";
import PageBase from "../components/PageBase";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SeekAdviceDialog from "../components/dialogs/SeekAdviceDialog";
import SeekAdviceFab from "../components/SeekAdviceFab";

const useStyles = makeStyles((theme) => ({
  headlinesContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const [seekAdviceOpen, setSeekAdviceOpen] = useState<boolean>(false);
  const { selectedAccount } = useSelector((state: RootState) => state.account);
  const { Track } = useTracking({
    page: `Dashboard`,
  });

  if (!selectedAccount?.account.id) {
    history.push("/account-select");
  }

  return (
    <PageBase>
      <Track>
        <DashboardProvider>
          <Grid className={classes.headlinesContainer} container spacing={2}>
            <Grid item xs={12}>
              <DashboardFilter />
            </Grid>
            <Grid item xs={12}>
              <DashboardHeadlines />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <AccountDetailsCard />
            </Grid>
            <Grid item xs={12} md={3}>
              <ELTeamCard />
            </Grid>
            <Grid item xs={12} md={2}>
              <TimeSummaryCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <TimeSummaryChartCard />
            </Grid>
            <Grid item xs={12}>
              <ContactCasesTableCard />
            </Grid>
          </Grid>
        </DashboardProvider>
        <SeekAdviceFab onClick={() => setSeekAdviceOpen(true)} />
        <SeekAdviceDialog open={seekAdviceOpen} onClose={() => setSeekAdviceOpen(false)} />
      </Track>
    </PageBase>
  );
}

export default Dashboard;
