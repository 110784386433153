import {
    Button, Checkbox, CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControlLabel, FormGroup,
    IconButton,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core";
import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import useELTeam from "../../hooks/queries/useELTeam";
import ResponsiveSelect from "../ui/ResponsiveSelect";
import {compareLastNames} from "../../utils/nameUtils";
import {AttachFile} from "@material-ui/icons";
import caseService, {Attachment} from "../../services/caseService";
import AttachmentsContainer from "../AttachmentsContainer";
import { useFileUploads } from "../../contexts/fileUploadContext";
import useSeekAdvice from "../../hooks/mutations/useSeekAdvice";
import {Alert} from "@material-ui/lab";
import {red} from "@material-ui/core/colors";
import useCaseDetails from "../../hooks/queries/useCaseDetails";
import useAccountAppSites from "../../hooks/queries/useAccountAppSites";
import {useSnackbar} from "notistack";
import CustomButton from "../CustomButton";

const useStyles = makeStyles((theme) => ({
    title: { 
        fontSize: 18, 
        fontWeight: 600 
    },
    formItem: {
        margin: theme.spacing(1, 0),
    },
    actionContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2, 3),
        alignItems: "center",
    },
    actionBtns: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    actionBtn: {
        marginLeft: theme.spacing(2),
    },
    discardButton: {
        color: red[900],
    },
}));

type SeekAdviceDialogProps = {
    open: boolean;
    onClose: () => void;
    attachments?: Attachment[];
    caseId?: number;
}

const initialFormErrorState = {
    subject: false,
    message: false,
    site: false,
};

const SeekAdviceDialog = ({ open, onClose, attachments, caseId } : SeekAdviceDialogProps) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    const [adviserId, setAdviserId] = useState<string | number>("empty");
    const [subject, setSubject] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [important, setImportant] = useState<boolean>(false);
    const [discardChangesOpen, setDiscardChangesOpen] = useState<boolean>(false);
    const [existingAttachments, setExistingAttachments] = useState(
        attachments ?? []
    );
    const [existingFilesToDelete, setExistingFilesToDelete] = useState<string[]>(
        []
    );
    const [formErrors, setFormErrors]= useState(initialFormErrorState);
    const [siteExternalId, setSiteExternalId] = useState<string>("");
    const [employee, setEmployee] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);

    const { selectedSite, selectedAccount, sites } = useSelector(
        (state: RootState) => state.account
    );

    const accountExternalIds = selectedSite
        ? [selectedSite.externalId]
        : Object.keys(sites);

    const caseDetails = useCaseDetails({
        caseId: caseId,
    });
    
    const {
        data: eLTeam,
        isLoading: elTeamLoading,
        error: eLTeamError,
    } = useELTeam({ accountExternalIds });
    
    const { 
        data: accountSitesData,
        isLoading: accountSitesLoading,
        error: accountSitesError,
    } = useAccountAppSites({
        childExternalIds: [
            ...(selectedAccount?.childExternalIds || []),
            selectedAccount?.account.externalId,
        ],
    });

    const {
        uploads,
        setUploads,
        setFailedUploads,
        handleFileUpload,
        uploadInProgress,
        handleNewFileDelete
    } = useFileUploads();
    
    const seekAdvice = useSeekAdvice();
    
    const advisers = useMemo(() => {
        return ([ ...(eLTeam || []), ...(caseDetails?.data?.advisers?.map(cc => ({ userId: cc.id, name: cc.name }))) || [] ])
            .reduce((acc, curr) => {
                if (!acc.find(item => item.userId === curr.userId))
                    acc.push(curr);
                return acc;
            }, [])
            .sort(compareLastNames);
        
    }, [eLTeam, caseDetails?.data?.advisers]);

    const handleClickCancel = () => {
        if (uploads.length > 0 || subject.length > 0 || message.length > 0 || employee.length > 0) {
            setDiscardChangesOpen(true);
            return;
        }
        onClose();
        handleFormReset();
    };

    const handleExistingFileDelete = (fileReference: string) => {
        setExistingFilesToDelete([...existingFilesToDelete, fileReference]);
        setExistingAttachments(
            existingAttachments.filter(
                (attachment) => attachment.reference !== fileReference
            )
        );
    };
    
    const handleSubjectChange = (e) => {
        const value = e.target.value;
        setSubject(value);
        const invalid = value.trim() === "";
        setFormErrors(prev => ({
            ...prev,
            subject: invalid,
        }));
    }

    const handleMessageChange = (e) => {
        const value = e.target.value;
        setMessage(value);
        const invalid = value.trim() === "";
        setFormErrors(prev => ({
            ...prev,
            message: invalid,
        }));
    };
    
    const handleFormReset = () => {
        setSubject("");
        setMessage("");
        setFormErrors(initialFormErrorState);
        setUploads([]);
        setFailedUploads([]);
        setImportant(false);
        setAdviserId("empty");
        setSiteExternalId("");
        setEmployee("");
        setDiscardChangesOpen(false);
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (subject === "" || message === "" || (!caseId && siteExternalId === "")) {
            setFormErrors({
                subject: subject === "",
                message: message === "",
                site: !caseId && siteExternalId === ""
            });
            return;
        }
        
        if (caseId)
            seekAdvice.mutate({ 
                caseId, 
                subject, 
                message, 
                attachments: uploads, 
                adviserIds: advisers.map(a => a.userId), 
                adviserId: typeof(adviserId) === "string" ? null : adviserId, 
                important,
            });
        
        else {
            try {
                setSaving(true);
                await caseService.addRaiseCaseRequest({
                    subject,
                    message,
                    employee,
                    attachments: uploads,
                    siteExternalId,
                    siteExternalIds: Object.keys(sites)
                });
                enqueueSnackbar("Request submitted. A response will be provided soon.");
            }
            catch (e) {
                enqueueSnackbar("Failed to submit request. Please try again.", { variant: "error" });
                console.error(e);
            }
            finally {
                setSaving(false);
            }
        }
        
        handleFormReset();
        onClose();
    }

    const handleCloseModal = async (event?: object, reason?: string) => {
        if (reason === "backdropClick")
            return;

        for (const file of uploads)
            await handleNewFileDelete(file.id);

        onClose();
        handleFormReset();
    }
    
    const handleSiteChange = (e) => {
        setSiteExternalId(e.target.value);
        setFormErrors(prev => ({
            ...prev,
            site: false,
        }));
    }
    
    return (
        <Dialog open={open} onClose={handleCloseModal} maxWidth="sm" fullWidth disableEscapeKeyDown>
            {discardChangesOpen ?
                <>
                    <DialogTitle>Discard Changes</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Any changes will be lost.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDiscardChangesOpen(false)} color="primary">Cancel</Button>
                        <Button onClick={handleCloseModal} className={classes.discardButton}>Discard</Button>
                    </DialogActions>
                </> :
                <>
                    <DialogTitle>
                        <Typography variant="body1" color="primary" className={classes.title}>
                            Seek Advice
                        </Typography>
                    </DialogTitle>
                    <form onSubmit={handleSubmit} noValidate>
                        <DialogContent>
                            {!!caseId ? 
                                <>
                                    {eLTeamError ? <Alert variant="outlined" severity="error">Failed to load your Employment Law Team</Alert> : null}
                                    {elTeamLoading ?
                                        <CircularProgress /> :
                                        <ResponsiveSelect
                                            className={classes.formItem}
                                            value={adviserId}
                                            label="To"
                                            options={[  { userId: "empty", name: "Any Adviser" }, ...(advisers || []) ]}
                                            onChange={e => setAdviserId(e.target.value)}
                                            fullWidth
                                            optionValueKey={"userId"}
                                            optionLabelKey={"name"}
                                        />}
                                </> : null}
                            {!caseId ? 
                                <>
                                    <ResponsiveSelect
                                        className={classes.formItem}
                                        value={siteExternalId}
                                        label="Site"
                                        options={accountSitesData || []}
                                        onChange={handleSiteChange}
                                        fullWidth
                                        optionValueKey="externalId"
                                        optionLabelKey="name"
                                        required
                                        error={formErrors.site}
                                        helperText={formErrors.site ? "Please select a site" : ""}
                                    />
                                    <TextField
                                        className={classes.formItem}
                                        value={employee}
                                        onChange={e => setEmployee(e.target.value)}
                                        label="Employee"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </> : null}
                            <TextField
                                className={classes.formItem}
                                value={subject}
                                onChange={handleSubjectChange}
                                label="Subject"
                                fullWidth
                                variant="outlined"
                                required
                                error={formErrors.subject}
                                helperText={formErrors.subject ? "Please enter a subject" : ""}
                            />
                            <TextField
                                className={classes.formItem}
                                value={message}
                                onChange={handleMessageChange}
                                label="Message"
                                fullWidth
                                multiline
                                minRows={8}
                                variant="outlined"
                                required
                                error={formErrors.message}
                                helperText={formErrors.message ? "Please enter a message" : ""}
                            />
                            <AttachmentsContainer existingAttachments={existingAttachments} handleExistingFileDelete={handleExistingFileDelete} />
                        </DialogContent>
                        <DialogActions className={classes.actionContainer}>
                            {!!caseId ?
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={important} onChange={e => setImportant(e.target.checked)} />} label="Mark as important" />
                                </FormGroup> : <div></div>}
                            <div className={classes.actionBtns}>
                                <IconButton
                                    color="primary"
                                    component="label"
                                    onChange={handleFileUpload}
                                >
                                    <input type="file" multiple hidden />
                                    <AttachFile />
                                </IconButton>
                                <Button
                                    disabled={saving}
                                    color="secondary"
                                    onClick={handleClickCancel}
                                    className={classes.actionBtn}
                                >
                                    Cancel
                                </Button>
                                <CustomButton
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    disabled={uploadInProgress}
                                    className={classes.actionBtn}
                                    isLoading={saving}
                                >
                                    Seek Advice
                                </CustomButton>
                            </div>
                        </DialogActions>
                    </form>
                </>}
        </Dialog>
    );
}

export default SeekAdviceDialog;