const consentCookieValues = {
  PARDOT: "myworknest-pardot",
};

const cookieExpiresAfter = () => {
  // Default to 1 week
  let now = new Date();
  let time = now.getTime();
  let expireTime = time + 7 * 24 * 60 * 60 * 1000;
  now.setTime(expireTime);
  return now.toUTCString();
};

export { consentCookieValues, cookieExpiresAfter };
