import { useReducer } from "react";

const actionTypes = { toggleCategory: "TOGGLE_CATEGORY" };

function categoryReducer(expandedCategoryIndexes, action) {
  switch (action.type) {
    case actionTypes.toggleCategory: {
      const closing = expandedCategoryIndexes.includes(action.index);
      return closing
        ? expandedCategoryIndexes.filter((x) => x !== action.index)
        : [...expandedCategoryIndexes, action.index];
    }
  }
}

const useExpandedCategories = () => {
  const [expandedCategories, dispatch] = useReducer(categoryReducer, []);

  const toggleCategory = (index) =>
    dispatch({ type: actionTypes.toggleCategory, index });

  return { expandedCategories, toggleCategory };
};

export default useExpandedCategories;
