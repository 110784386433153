import { makeStyles } from "@material-ui/core";
import {
  ETClaimDetailsCard,
  InsuranceDetailsCard,
  PercentageOfSuccessClaimTypeCard,
  ETOutcomeCard,
  Milestones,
  ETCostsCard,
  ETAwardsSettlementsCard,
} from "./cards/etClaimDetails";
import useETCaseDetails from "../hooks/queries/useETCaseDetails";
import useCaseActions from "../hooks/queries/useCaseActions";

const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
}));

type ETClaimDetailsProps = {
  caseId: number;
};

const ETClaimDetails = ({ caseId }: ETClaimDetailsProps) => {
  const classes = useStyles();
  const { data, isLoading, isError, error } = useETCaseDetails(caseId);
  const {
    data: caseActions,
    isLoading: caseActionsIsLoading,
    isError: caseActionsIsError,
    error: caseActionsError,
  } = useCaseActions(caseId);

  return (
    <div className={classes.layout}>
      <ETClaimDetailsCard
        caseNum={data?.caseNum}
        earlyConciliationNumber={data?.earlyConciliationNumber}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      <InsuranceDetailsCard
        etClaim={data}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      <ETCostsCard
        estimatedCouncilFees={data?.estimatedCouncilFees}
        actualCouncilFees={data?.actualCouncilFees}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      <ETAwardsSettlementsCard
        etClaim={data}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      <PercentageOfSuccessClaimTypeCard
        claims={data?.claims}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      <Milestones
        data={caseActions}
        isLoading={caseActionsIsLoading}
        isError={caseActionsIsError}
        error={caseActionsError}
      />
      <ETOutcomeCard caseOutcome={data?.caseOutcome} />
    </div>
  );
};

export default ETClaimDetails;
