import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

type UseCaseTimeProps = {
  caseId: number;
};

const useCaseTime = ({ caseId }: UseCaseTimeProps) =>
  useQuery(
    [queryKeys.caseTime, caseId],
    () => caseService.fetchCaseTime(caseId),
    {
      enabled: !!caseId,
    }
  );

export default useCaseTime;
