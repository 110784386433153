import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const useAccountAppSites = ({ childExternalIds }) =>
  useQuery(
    [queryKeys.accountSites, childExternalIds?.join(",")],
    () =>
      accountService.fetchAccountsForApp({
        accountExternalIds: childExternalIds,
      }),
    {
      enabled: !!childExternalIds.length,
      staleTime: Infinity,
    }
  );

export default useAccountAppSites;
