import React, { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { allSitesOption } from "../constants/siteSelectConstants";
import { Business } from "@material-ui/icons";
import { makeStyles, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import useAccountAppSites from "../hooks/queries/useAccountAppSites";
import { selectSite, setSites } from "../redux/actions/accountActions";
import useIsMobile from "../hooks/useIsMobile";
import { RootState } from "../redux/store";

const useStyles = makeStyles((theme) => ({
  siteSelectIcon: {
    marginRight: theme.spacing(1),
  },
}));

const SiteSelect = ({
  size = "medium",
  variant = "outlined",
  className,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedAccount, selectedSite } = useSelector(
    (state: RootState) => state.account
  );
  const { data: accountSitesData } = useAccountAppSites({
    childExternalIds: [
      ...(selectedAccount?.childExternalIds || []),
      selectedAccount?.account.externalId,
    ],
  });

  const isMobile = useIsMobile();

  const handleSiteChange = (event, newValue) => {
    dispatch(
      selectSite(newValue.name === allSitesOption.name ? null : newValue)
    );
  };

  useEffect(() => {
    if (!accountSitesData) return;
    dispatch(setSites({ sites: accountSitesData }));
  }, [dispatch, accountSitesData]);

  if (!accountSitesData?.length) return null;

  return (
    <Autocomplete
      size={size}
      fullWidth
      blurOnSelect
      className={className}
      value={selectedSite || allSitesOption}
      onChange={handleSiteChange}
      getOptionLabel={(option) => option.name}
      disableClearable
      renderOption={(option) => (
        <>
          <Business className={classes.siteSelectIcon} />
          {option.name}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a site"
          variant={variant}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          SelectProps={{
            native: isMobile,
          }}
        />
      )}
      options={[allSitesOption, ...accountSitesData]}
    />
  );
};

export default SiteSelect;
