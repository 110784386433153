import {
  Grid,
  makeStyles,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { sub } from "date-fns";
import React, { useState } from "react";
import { useAuth } from "../contexts/authContext";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import SiteSelect from "./SiteSelect";
import ResponsiveSelect from "./ui/ResponsiveSelect";
import { discreteDateFilterList } from "../constants/filterConstants";
import { useDashboardFilter } from "../contexts/dashboardContext";
import { tablePage } from "../constants/tablePageConstants";
import { setContactCasesTablePage } from "../redux/actions/tablePageActions";
import { RootState } from "../redux/store";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 4,
    minWidth: 238,
    marginLeft: 10,
  },
  datePickerContainer: {
    width: "100%",
    display: "grid",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  filterSwitchItems: {
    marginTop: theme.spacing(2),
  },
}));

const tableKey = tablePage.CONTACT_CASES_TABLE;

export default function DashboardFilter() {
  const dispatch = useDispatch();
  const { selectedAccount } = useSelector((state: RootState) => state.account);
  const { contactCasesTable } = useSelector(
    (state: RootState) => state.tablePage
  );
  const { isViewAllCasesAllowedForSites } = useAuth();
  const {
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    setDisplayAll,
    displayAll,
  } = useDashboardFilter();
  const classes = useStyles();
  const [filterBy, setFilterBy] = useState(12);

  const handleDateChange = (action, date, filter: any = null) => {
    if (filter) {
      setFilterBy(filter);
      let newDate = new Date();
      newDate.setHours(0);
      newDate.setMinutes(0);
      setToDate(newDate);
      setFromDate(filter === "0" ? null : sub(newDate, { months: filter }));
    } else if (date) {
      let newDate = date;
      newDate.setHours(0);
      newDate.setMinutes(0);
      action(newDate);
    }
  };

  const handleShowAllCasesChange = (e) => {
    dispatch(
      setContactCasesTablePage({
        ...contactCasesTable,
        key: tableKey,
        page: 0,
      })
    );
    setDisplayAll(e.target.checked);
  };

  const isViewAllAllowed = isViewAllCasesAllowedForSites(
    selectedAccount?.childExternalIds ?? []
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography variant="h2">
            {displayAll && isViewAllAllowed ? "All Cases" : "My Cases"}
          </Typography>
          {isViewAllAllowed && (
            <Grid item className={classes.filterSwitchItems}>
              <FormControlLabel
                control={
                  <Switch
                    checked={displayAll}
                    onChange={handleShowAllCasesChange}
                    name="showAllCases"
                    color="primary"
                  />
                }
                label="Show All Cases"
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          spacing={2}
          container
          xs={12}
          md={9}
          justifyContent="flex-end"
        >
          <Grid item xs={6} md={3}>
            <SiteSelect size="small" />
          </Grid>
          <Grid item xs={6} md={3}>
            <ResponsiveSelect
              size="small"
              label="Filter by"
              fullWidth
              value={filterBy}
              onChange={(e) => handleDateChange(null, null, e.target.value)}
              options={discreteDateFilterList}
              optionLabelKey="label"
              optionValueKey="value"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <KeyboardDatePicker
              size="small"
              fullWidth
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label="From Date"
              value={fromDate}
              onChange={(e) => handleDateChange(setFromDate, e)}
              maxDate={new Date()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <KeyboardDatePicker
              size="small"
              fullWidth
              inputVariant="outlined"
              format="dd/MM/yyyy"
              label="To Date"
              value={toDate}
              onChange={(e) => handleDateChange(setToDate, e)}
              maxDate={new Date()}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
