import useCaseTime from "../hooks/queries/useCaseTime";
import TimeCard from "./cards/caseTime/TimeCard";

type CaseTimeProps = {
  caseId: number;
};

const CaseTime = ({ caseId }: CaseTimeProps) => {
  const { data: caseTimeData, isLoading } = useCaseTime({ caseId });

  const {
    budgetedSeconds = 0,
    budgetedHours = 0,
    chargeableTime = 0,
    nonChargeableTime = 0,
    totalActivityTime = 0,
    chargeableTimeUsed = 0,
    remainingBudgetedMinutes = 0,
    isUnitisedTime = false,
    isChargeable = false,
  } = caseTimeData || {};

  return (
    <TimeCard
      isLoading={isLoading}
      isChargeable={isChargeable}
      isUnitised={isUnitisedTime}
      hasBudgetSet={!!budgetedSeconds}
      totalTime={totalActivityTime}
      nonChargeableTime={nonChargeableTime}
      chargeableTime={chargeableTime}
      budgetHours={budgetedHours}
      budgetMinutes={remainingBudgetedMinutes}
      budgetTime={budgetedSeconds}
      chargeableTimeUsedPercentage={chargeableTimeUsed}
    />
  );
};

export default CaseTime;
