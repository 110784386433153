import axios from "../utils/axios";

const tutorialEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/tutorial`;

const tutorialAppearanceMode = {
  FIRST_LOGIN: 0,
  EVERY_TIME: 1,
} as const;

export type TutorialAppearanceMode =
  (typeof tutorialAppearanceMode)[keyof typeof tutorialAppearanceMode];

export type Tutorial = {
  id: number;
  route: string;
  appearanceMode: TutorialAppearanceMode;
  appearanceModeDescription: string;
  title: string;
  steps: string[];
};

const fetchAllActive = () =>
    axios.get<never, { [path: string]: Tutorial }>(`${tutorialEndpointUrl}?saasAppId=${process.env.REACT_APP_CASENEST_APP_ID}`);
const fetchAllActiveList = () =>
  axios.get<never, any>(`${tutorialEndpointUrl}/list`);
const add = (tutorial) =>
  axios.post<never, any>(`${tutorialEndpointUrl}`, tutorial);
const fetchById = (tutorialId) =>
  axios.get<never, any>(`${tutorialEndpointUrl}/${tutorialId}`);
const deleteById = (tutorialId) =>
  axios.delete(`${tutorialEndpointUrl}/${tutorialId}`);
const update = (tutorial) => axios.patch(`${tutorialEndpointUrl}`, tutorial);
const fetchViewsForUser = () =>
  axios.get<never, string[]>(`${tutorialEndpointUrl}/views`);
const addViewForUser = (tutorialId) =>
  axios.post<never, any>(`${tutorialEndpointUrl}/views/${tutorialId}`);

const tutorialService = {
  fetchAllActive,
  fetchAllActiveList,
  add,
  fetchById,
  deleteById,
  update,
  fetchViewsForUser,
  addViewForUser,
};

export default tutorialService;
