const getInitials = (name = "") => {
  if (!name) return "";
  var names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const compareLastNames = (a, b) => {
  a = a.name;
  b = b.name;
  let splitA = a.split(" ");
  let splitB = b.split(" ");
  let lastA = splitA[splitA.length - 1];
  let lastB = splitB[splitB.length - 1];

  if (lastA < lastB) return -1;
  if (lastA > lastB) return 1;
  return 0;
};

export { getInitials, compareLastNames };
