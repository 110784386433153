import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    maxWidth: "240px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  },
  fullGrid: {
    borderBottom: `solid 2px ${theme.palette.secondary.main}`,
    gridColumn: "1 / span 4",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  rightHalf: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
  },
  titleWrapper: {
    minWidth: "max-content",
  },
  subtitle: {
    fontWeight: 600,
  },
}));

type ETOutcomeCardProps = {
  caseOutcome: string | undefined;
};

const ETOutcomeCard = ({ caseOutcome }: ETOutcomeCardProps) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <div className={classes.fullGrid}>
          <div className={classes.titleWrapper}>
            <Typography variant="h6">Outcome</Typography>
          </div>
        </div>

        <div>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Outcome
          </Typography>
          <Typography variant="body2">{caseOutcome}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default ETOutcomeCard;
