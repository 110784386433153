import axios from "../utils/axios";
import stringUtils from "../utils/stringUtils";

const getELTeam = async (accountExternalIds) => {
  const queryString = stringUtils.buildQueryStringList(
    "accountExternalIds",
    accountExternalIds
  );
  return axios.get<never, any>(
    `${process.env.REACT_APP_CASENEST_API_URL}/client/getelteam/${queryString}`
  );
};

const teamService = {
  getELTeam,
};

export default teamService;
