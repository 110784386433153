import React from "react";

import { VictoryTooltip } from "victory";
import LabelLine from "./LabelLine";
import { getXOffset, getYOffset, getAverage } from "./utils/math";
import { formatShortTime } from "../../../utils/dateTimeUtils";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    pielabel: {
        fill: "rgb(51, 51, 51)",
        fontSize: "11px",
    },
    tooltip: {
        padding: 12,
        color: "white",
        background: "#222222",
    },
}));

const percentShowLabel = 2;

const Label = (props) => {
    const {
        datum,
        innerRadius,
        radius,
        slice: { startAngle, endAngle },
        cx,
        cy
    } = props;

    const classes = useStyles();

    // calculation
    const middleRadius = getAverage([innerRadius, radius]);
    const midAngle = getAverage([endAngle, startAngle]);
    const labelOffset = radius + middleRadius / 1.9;
    const tempX = cx + getXOffset(labelOffset, midAngle);
    const y = cy + getYOffset(labelOffset, midAngle);

    const textAnchor = cx < tempX ? "start" : "end";
    const x = cx < tempX ? tempX + 15 : tempX - 15;

    return (
        <g>
            {props.datum.percentage > percentShowLabel &&
                <>
                    <text x={x} y={y} textAnchor={textAnchor} className={classes.pielabel}>
                        {datum.label}
                    </text>
                    <LabelLine
                        cx={cx}
                        cy={cy}
                        middleRadius={middleRadius}
                        radius={radius}
                        midAngle={midAngle}
                        />
                </>
            }
            <VictoryTooltip 
                {...props}
                text={({ datum }) =>
                    `${datum.label}: ${datum.percentage}% (${formatShortTime(datum.value)})`
                }
            />
        </g>
    );
};

Label.defaultEvents = VictoryTooltip.defaultEvents;

export default Label;