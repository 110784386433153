import React from "react";
import {HelpOutline} from "@material-ui/icons";
import {Fab, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed",
        bottom: 0,
        right: 0,
        marginRight: theme.spacing(6),
        marginBottom: theme.spacing(3),
        zIndex: 100,
        transform: "scale(1.2)",
    },
    fabIcon: {
        marginRight: theme.spacing(1),
    }
}));

type SeekAdviceFabProps = {
    onClick: () => void
}

const SeekAdviceFab = ({ onClick } : SeekAdviceFabProps) => {
    const classes = useStyles();
    return (
        <Fab className={classes.fab} variant="extended" color="secondary" size="large" onClick={onClick}>
            <HelpOutline className={classes.fabIcon} />
            <div>Seek Legal Advice</div>
        </Fab>
    )
};

export default SeekAdviceFab;