import React from "react";

import { getXOffset, getYOffset } from "./utils/math";

const LabelLine = (props) => {
    const { cx, cy, midAngle, middleRadius, radius } = props;
    const xStart = cx + getXOffset(middleRadius, midAngle);
    const yStart = cy + getYOffset(middleRadius, midAngle);

    const offSetMiddle = 2.8 * radius - middleRadius;
    const xMiddle = cx + getXOffset(offSetMiddle, midAngle);
    const yMiddle = cy + getYOffset(offSetMiddle, midAngle) + 6;

    const offSetEnd = 3.5 * radius - middleRadius;
    const xEnd = cx + getXOffset(offSetEnd, midAngle);

    return (
        <polyline
            style={{
                opacity: "0.4",
                fill: "none",
                stroke: "black",
                strokeWidth: "2px",
            }}
            points={`${xStart},${yStart} ${xMiddle},${yMiddle} ${xEnd},${yMiddle}`}
        />
    );
};

export default LabelLine;
