import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import userService from "../../services/userService";

const useUserProfile = (enabled = true) =>
  useQuery<any, any>(
    queryKeys.userProfile,
    () => userService.getUserProfile(),
    {
      staleTime: Infinity,
      enabled,
    }
  );

export default useUserProfile;
