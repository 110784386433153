import { createTheme } from "@material-ui/core/styles";
import FinancierFont from "./fonts/financier-regular.woff2";
import SailecFont from "./fonts/sailec-regular.otf";

const heading = {
  fontFamily: ["Financier", "Times New Roman", "serif"].join(","),
};

const Financier = {
  fontFamily: "Financier",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `
    local('Financier'),
    url(${FinancierFont}) format('woff2')
  `,
};

const Sailec = {
  fontFamily: "Sailec",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `
    local('Sailec'),
    url(${SailecFont}) format('opentype')
  `,
};

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [Financier, Sailec],
        html: {
          WebkitFontSmoothing: "auto",
          height: "100%",
        },
        body: {
          height: "100%",
          color: "#12054D",
          "& th, td, li": {
            color: "#12054D !important",
          },
        },
        ".MuiTablePagination-root": {
          color: "#12054D !important",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Sailec", "Arial", "sans-serif"].join(","),
    h1: heading,
    h2: heading,
    h3: heading,
    h4: heading,
    h5: heading,
    h6: heading,
    button: {
      textTransform: "none",
    },
  },
  palette: {
    text: {
      primary: "#12054D",
    },
    primary: {
      light: "#5C45C7",
      mainOpaque: "#12054D58",
      main: "#12054D",
    },
    secondary: {
      light: "#AB96FA",
      mainOpaque: "#7A5CF033",
      main: "#7A5CF0",
      dark: "#5C45C7",
    },
    successGreen: "#2E7D32",
    errorRed: "#B00020",
    neutralGrey: "#716994",
  },
});

export default theme;
