import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import tileService from "../../services/tileService";

const useQuickNavigation = (accountExternalIds?: string[]) =>
  useQuery(
    [queryKeys.quickNavigation, accountExternalIds],
    () => tileService.fetchQuickNavigation(accountExternalIds ?? []),
    { enabled: (accountExternalIds?.length ?? 0) > 0 }
  );

export default useQuickNavigation;
