import React from "react";
import { Route } from "react-router-dom";

const RouteMapper = ({ routes }) =>
  routes.map(({ path, component: Component, exact, children }, index) =>
    children ? (
      children.map(({ childPath, component: ChildComponent }, childIndex) => (
        <Route key={childIndex} path={childPath} exact={!!exact}>
          <ChildComponent />
        </Route>
      ))
    ) : (
      <Route key={index} path={path} exact={!!exact}>
        <Component />
      </Route>
    )
  );

export default RouteMapper;
