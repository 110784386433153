import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const useInitialData = () =>
  useQuery(
    [queryKeys.initialData],

    () => caseService.fetchInitialData(),
    {
      staleTime: Infinity,
    }
  );

export default useInitialData;
