export const SET_ROOT_ACCOUNTS = "SET_ROOT_ACCOUNTS";
export const SELECT_SITE = "SELECT_SITE";
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const SET_MENTIONS = "SET_MENTIONS";

export const SET_SITES = "SET_SITES";
export const HIDE_CASENEST_TERMS_BANNER = "HIDE_CASENEST_TERMS_BANNER";

// page
export const SET_PAGE = "SET_PAGE";

// tutorial
export const SET_IS_TUTORIAL_OPEN = "SET_IS_TUTORIAL_OPEN";
export const SET_TUTORIAL = "SET_TUTORIAL";

// cookie consent dialog
export const SET_COOKIE_JAR_OPEN = "SET_COOKIE_JAR_OPEN";
export const SET_COOKIE_JAR_STEP = "SET_COOKIE_JAR_STEP";

// table page
export const SET_TABLE_PAGE = "SET_TABLE_PAGE";
export const SET_CASES_TABLE_PAGE = "SET_CASES_TABLE_PAGE";
export const SET_CONTACT_CASES_TABLE_PAGE = "SET_CONTACT_CASES_TABLE_PAGE";

// adviser
export const ADD_PHOTOS = "ADD_PHOTOS";
