import React from "react";
import { useDashboardFilter } from "../contexts/dashboardContext";
import CardBase from "./cards/CardBase";
import useTimeSummary from "../hooks/queries/useTimeSummary";
import { formatShortTime } from "../utils/dateTimeUtils";
import { useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { RootState } from "../redux/store";
import {
  VictoryPie,
  VictoryTheme,
} from "victory";
import Label from "./ui/pieChart/Label";

const useStyles = makeStyles(() => ({
  pieSettings: {
    textAlign: "center",
    position: "relative",
    height: 280,
  },
  overlay: {
    position: "absolute",
    top: 10,
    right: 50,
    bottom: 0,
    left: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 28,
    color: "#000",
    textAlign: "center",
    pointerEvents: "none",
  },
}));

function TimeSummaryChartCard() {
  const classes = useStyles();
  const { fromDate, toDate, displayAll } = useDashboardFilter();
  const { selectedSite, sites } = useSelector(
    (state: RootState) => state.account
  );

  const { data, isLoading, error, isFetching } = useTimeSummary({
    fromDate,
    toDate,
    displayAll,
    accountExternalIds: selectedSite
      ? [selectedSite.externalId]
      : Object.keys(sites),
  });

  const chartData =
    data?.details?.length &&
    data.details.map((s) => ({
      id: s.caseType,
      label: s.caseType,
      value: s.totalTimeSeconds,
      percentage: Math.round((s.totalTimeSeconds / data.totalCaseTime) * 100),
    }));

  const totalTime =
    data?.details?.length && formatShortTime(data.totalCaseTime);

    const ResponsivePieChart = ({
        height,
        width,
        innerRadius,
        radius,
        nameKey,
        valueKey,
        data
    }) => {
        const percentShowLabel = 5;
        const centerX = width / 2;
        const centerY = height / 2;
        return (
            <div className={classes.pieSettings}>
                <VictoryPie
                    theme={VictoryTheme.material}
                    height={height}
                    width={width}
                    radius={radius}
                    innerRadius={innerRadius}
                    labelRadius={130}
                    data={data}
                    x={nameKey}
                    y={valueKey}
                    labelComponent={
                        <Label
                            innerRadius={innerRadius}
                            radius={radius}
                            nameKey={nameKey}
                            valueKey={valueKey}
                            cx={centerX}
                            cy={centerY}
                        />
                    }
                />
                <Typography className={classes.overlay}>{totalTime}</Typography>
            </div>
        );
    };
    ResponsivePieChart.defaultProps = {
        height: 275,
        width: 300,
        innerRadius: 100,
        radius: 75,
        nameKey: "label",
        valueKey: "percentage"
    };

  const cardContent = () => {
    if (chartData) return <ResponsivePieChart data={chartData} />;
    return <Typography>No Time Data</Typography>;
  };

  return (
    <CardBase
      title={
        displayAll
          ? "Case Time breakdown - All Cases"
          : "Case Time breakdown - My Cases"
      }
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      fullHeight
    >
      {cardContent()}
    </CardBase>
  );
}

export default TimeSummaryChartCard;
