import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { BaseCardProps } from "../../../types/baseCardProps";

const useStyles = makeStyles((theme) => ({
  boxSpacing: {
    gap: theme.spacing(3),
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  rightHalf: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
  },
  fullGrid: {
    gridColumn: "1 / span 4",
    display: "flex",
    alignItems: "center",
    borderBottom: `solid 2px ${theme.palette.secondary.main}`,
  },
  leftHalf: {
    minWidth: "max-content",
  },
  subtitle: {
    fontWeight: 600,
  },
  loadingErrorContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

interface ETClaimDetailsCardProps extends BaseCardProps {
  caseNum?: string;
  earlyConciliationNumber?: string;
}

export const ETClaimDetailsCard = ({
  caseNum = "-",
  earlyConciliationNumber = "-",
  isLoading,
  isError,
  error,
}: ETClaimDetailsCardProps) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <div className={classes.boxSpacing}>
          <div className={classes.fullGrid}>
            <Typography className={classes.leftHalf} variant="h6">
              ET Claim Details
            </Typography>
          </div>
          {isLoading && (
            <>
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
            </>
          )}
          {isError && (
            <div className={classes.loadingErrorContainer}>
              <Alert variant="outlined" severity="error">
                {error?.message || "Unable to load ET case details"}
              </Alert>
            </div>
          )}
          {!isLoading && !isError && (
            <>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  ET Case Number
                </Typography>
                <Typography variant="body2">{caseNum}</Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Early Conciliation Number
                </Typography>
                <Typography variant="body2">
                  {earlyConciliationNumber}
                </Typography>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ETClaimDetailsCard;
