import { SET_COOKIE_JAR_OPEN, SET_COOKIE_JAR_STEP } from "../types";

export type CookieDialogStep = "initial" | "manage";

type CookieState = {
  isOpen: boolean;
  step: CookieDialogStep;
};

const initialState: CookieState = {
  isOpen: false,
  step: "initial",
};

function cookieReducer(state = initialState, action): CookieState {
  switch (action.type) {
    case SET_COOKIE_JAR_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    case SET_COOKIE_JAR_STEP:
      return {
        ...state,
        step: action.payload.step,
      };
    default:
      return state;
  }
}

export default cookieReducer;
