const featureTileWidth = 180;
const headlineTileWidth = 120;
const headlineTileHeight = 80;
const drawerWidth = 260;
const impersonationBannerHeight = 20;
const termsBannerHeight = 70;

const contentPaddingMultiplier = 3;
const homePageSectionsPaddingMultiplier = 2;
const tilesGridSpacingMultiplier = 2;
const documentTilesGridSpacingMultiplier = 3;

const loginFormWidth = 300;

const layoutConstants = {
  drawerWidth,
  featureTileWidth,
  headlineTileWidth,
  headlineTileHeight,
  contentPaddingMultiplier,
  homePageSectionsPaddingMultiplier,
  tilesGridSpacingMultiplier,
  impersonationBannerHeight,
  documentTilesGridSpacingMultiplier,
  loginFormWidth,
  termsBannerHeight,
};

export default layoutConstants;
