import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { formatPriceGBP } from "../../../utils/priceUtils";
import { BaseCardProps } from "../../../types/baseCardProps";

const useStyles = makeStyles((theme) => ({
  boxSpacing: {
    gap: theme.spacing(3),
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  rightHalf: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
  },
  fullGrid: {
    gridColumn: "1 / span 4",
    display: "flex",
    alignItems: "center",
    borderBottom: `solid 2px ${theme.palette.secondary.main}`,
  },
  leftHalf: {
    minWidth: "max-content",
  },
  subtitle: {
    fontWeight: 600,
  },
  loadingErrorContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

interface InsuranceDetailsCardProps extends BaseCardProps {
  estimatedCouncilFees?: number;
  actualCouncilFees?: number;
}

const ETCostsCard = ({
  estimatedCouncilFees,
  actualCouncilFees,
  isLoading,
  isError,
  error,
}: InsuranceDetailsCardProps) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <div className={classes.boxSpacing}>
          <div className={classes.fullGrid}>
            <Typography className={classes.leftHalf} variant="h6">
              Costs
            </Typography>
          </div>
          {isLoading && (
            <>
              <Skeleton height={40} variant="rect" />
              <Skeleton height={40} variant="rect" />
            </>
          )}
          {isError && (
            <div className={classes.loadingErrorContainer}>
              <Alert variant="outlined" severity="error">
                {error?.message || "Unable to load Cost details"}
              </Alert>
            </div>
          )}
          {!isLoading && !isError && (
            <>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Estimated Counsel Fees
                </Typography>
                <Typography variant="body2">
                  {formatPriceGBP(estimatedCouncilFees)}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Actual Counsel Fees
                </Typography>
                <Typography variant="body2">
                  {formatPriceGBP(actualCouncilFees)}
                </Typography>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ETCostsCard;
