import React from "react";
import { Collapse, Divider, List, makeStyles } from "@material-ui/core";
import { Phone as PhoneIcon } from "@material-ui/icons";
import SidebarCategory from "./SidebarCategory";
import SidebarLink from "./SidebarLink";
import NavLinkWrapper from "./NavLinkWrapper";
import { menuItems } from "../../routes";
import useExpandedCategories from "../../hooks/useExpandedCategories";
import useIsMobile from "../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  navigationContainer: {
    flexGrow: 1,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    overflowY: "auto",
  },
  divider: {
    background: "rgba(255, 255, 255, 0.12)",
  },
}));

const contactPhoneNumber = "0345 226 8393";

function DrawerMenuItems() {
  const classes = useStyles();
  const { expandedCategories, toggleCategory } = useExpandedCategories();
  const isMobile = useIsMobile();

  return (
    <div className={classes.navigationContainer}>
      <List disablePadding>
        <div>
          {menuItems?.map((menuItem, index) => (
            <React.Fragment key={index}>
              {menuItem.children ? (
                <React.Fragment key={index}>
                  <SidebarCategory
                    isOpen={expandedCategories.includes(index)}
                    isCollapsable
                    name={menuItem.label}
                    icon={menuItem.icon}
                    button
                    disableRipple
                    onClick={() => toggleCategory(index)}
                  />
                  <Collapse
                    in={expandedCategories.includes(index)}
                    timeout="auto"
                    unmountOnExit
                  >
                    {menuItem.children.map((route, subIndex) => (
                      <SidebarLink
                        key={subIndex}
                        name={route.label}
                        to={menuItem.path + route.path}
                        icon={route.icon}
                      />
                    ))}
                  </Collapse>
                </React.Fragment>
              ) : (
                <SidebarCategory
                  name={menuItem.label}
                  to={menuItem.path}
                  activeClassName="active"
                  component={NavLinkWrapper}
                  icon={menuItem.icon}
                  exact
                />
              )}
            </React.Fragment>
          ))}
          {isMobile && (
            <>
              <Divider className={classes.divider} />
              <SidebarCategory
                name={`Need Help? Call: ${contactPhoneNumber}`}
                activeClassName="active"
                component="a"
                icon={PhoneIcon}
                exact
                href={`tel:${contactPhoneNumber}`}
              />
            </>
          )}
        </div>
      </List>
    </div>
  );
}

export default DrawerMenuItems;
