import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";

const useContactCasesForHeadlines = ({
  fromDate,
  toDate,
  displayAll,
  externalIds,
}) =>
  useQuery<any, any>(
    [
      queryKeys.contactCasesForHeadlines,
      fromDate,
      toDate,
      displayAll,
      externalIds?.join(","),
    ],

    () =>
      caseService.fetchContactCasesForHeadlines(
        fromDate,
        toDate,
        displayAll,
        externalIds
      ),
    {
      enabled: !!externalIds?.length,
    }
  );

export default useContactCasesForHeadlines;
