import {alpha, IconButton, LinearProgress, ListItem, ListItemText, makeStyles, Typography} from "@material-ui/core";
import {UploadStatus} from "../constants/uploadStatus";
import formatBytes from "../utils/formatBytes";
import React, {ReactNode} from "react";
import {Clear} from "@material-ui/icons";
import {green, grey, red} from "@material-ui/core/colors";
import {Attachment} from "../services/caseService";
import { useFileUploads } from "../contexts/fileUploadContext";

const useStyles = makeStyles((theme) => ({
    listItem: {
        backgroundColor: alpha(grey[300], 0.3),
        borderRadius: "12px",
        margin: theme.spacing(1, 0),
    },
    listItemPrimary: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    attachmentsContainer: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
        borderRadius: 4,
    },
    attachmentsTitle: {
        fontSize: 13,
        fontWeight: 600,
    },
    chipContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: theme.spacing(1),
    },
    completeText: {
        color: green[700],
    },
    progressContainer: {
        display: "flex",
        flexDirection: "column",
    },
    sizeContainer: {
        display: "flex",
        alignItems: "center",
    },
    queued: {
        marginLeft: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
    progressBar: {
        width: "100%",
        marginRight: theme.spacing(1),
    },
    errorMessage: {
        color: red[800],
    },
}));

type UploadedFileProps = {
    name: string,
    onClear: () => void,
    secondaryComponent: ReactNode,
    clearDisabled?: boolean
}

const UploadedFile = ({ name, onClear, secondaryComponent = null, clearDisabled = false, } : UploadedFileProps) => {
    const classes = useStyles();
    
    return (
        <ListItem className={classes.listItem}>
            <ListItemText
                primary={
                    <div className={classes.listItemPrimary}>
                        <Typography
                            color="primary"
                            variant="subtitle2"
                        >
                            {name}
                        </Typography>
                        <IconButton
                            size="small"
                            onClick={onClear}
                            disabled={clearDisabled}
                        >
                            <Clear />
                        </IconButton>
                    </div>
                }
                secondary={secondaryComponent}
                disableTypography
            />
        </ListItem>
    )
}

type AttachmentsContainerProps = {
    existingAttachments: Attachment[];
    handleExistingFileDelete: (fileReference : string) => void;
}

const AttachmentsContainer = ({ existingAttachments, handleExistingFileDelete } : AttachmentsContainerProps) => {
    const classes = useStyles();
    const {
        uploads,
        failedUploads,
        handleClearFailedUpload,
        handleNewFileDelete,
    } = useFileUploads();
    
    if (uploads.length === 0 && existingAttachments.length === 0 && failedUploads.length === 0)
        return null;
    
    return (
        <div className={classes.attachmentsContainer}>
            <Typography variant="body2" className={classes.attachmentsTitle}>Attachments</Typography>
            <div className={classes.chipContainer}>
                {uploads.map(file =>
                    <UploadedFile
                        key={file.id}
                        name={file.file?.name || ""}
                        onClear={() => handleNewFileDelete(file.id)}
                        secondaryComponent={file.status === UploadStatus.COMPLETE ?
                            <Typography variant="subtitle2" className={classes.completeText}>Completed</Typography> :
                            (file.status === UploadStatus.IN_PROGRESS || file.status === UploadStatus.QUEUED) ?
                                <div className={classes.progressContainer}>
                                    <div className={classes.sizeContainer}>
                                        <Typography variant="caption">{formatBytes(file.file?.size || 0, 0)}</Typography>
                                        <Typography variant="caption" className={classes.queued}>{file.status === UploadStatus.QUEUED ? "Queued" : "Uploading"}</Typography>
                                    </div>
                                    {file.status === UploadStatus.IN_PROGRESS && <LinearProgress className={classes.progressBar} variant="indeterminate" />}
                                </div> :
                                null
                        }
                    />
                )}
                {existingAttachments.map((attachment) => (
                    <UploadedFile
                        key={attachment.reference}
                        name={attachment.filename}
                        onClear={() => handleExistingFileDelete(attachment.reference)}
                        secondaryComponent={null}
                    />
                ))}
                {failedUploads.map(file =>
                    <UploadedFile
                        key={file.id}
                        name={file.name}
                        onClear={() => handleClearFailedUpload(file.id)}
                        secondaryComponent={<Typography variant="caption" className={classes.errorMessage}>{file.message}</Typography>}
                    />
                )}
            </div>
        </div>
    );
}

export default AttachmentsContainer;