const contactCases = "contact-cases";
const contactCasesForHeadlines = "contact-cases-for-headlines";
const caseDetails = "case-details";
const eTCaseDetails = "et-case-details";
const initialData = "initial-data";
const gatherDocumentVersions = "gather-document-versions";
const gatherDocumentPdf = "gather-document-pdf";
const retrieveDocument = "retrieve-document";
const retrieveVideo = "retrieve-document";
const caseActivities = "case-activities";
const caseActivityEmail = "case-activity-email";

const accountDetails = "account-details";
const timeSummary = "time-summary";
const dashboardCases = "dashboard-cases";

const eLTeam = "el-team";

const userProfile = "user-profile";

const documents = "documents";

const tutorials = "tutorials";
const tutorialsList = "tutorials-list";
const tutorial = "tutorial";
const tutorialViews = "tutorial-views";

const appContacts = "app-contacts";
const caseTypes = "case-types";
const caseFiles = "case-files";
const caseTime = "case-time";
const caseActions = "case-actions";

const accountSites = "account-sites";

const quickNavigation = "quick-navigation";

const queryKeys = {
  contactCases,
  contactCasesForHeadlines,
  caseDetails,
  eTCaseDetails,
  caseActivities,
  caseActivityEmail,
  initialData,
  gatherDocumentVersions,
  gatherDocumentPdf,
  retrieveDocument,
  retrieveVideo,
  accountDetails,
  timeSummary,
  dashboardCases,
  eLTeam,
  userProfile,
  documents,
  tutorials,
  tutorialsList,
  tutorial,
  tutorialViews,
  appContacts,
  caseTypes,
  caseFiles,
  accountSites,
  quickNavigation,
  caseTime,
  caseActions,
};

export default queryKeys;
