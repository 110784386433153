import React, { useState, useMemo } from "react";
import clsx from "clsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  StepConnector,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@material-ui/core";
import CardBase from "./cards/CardBase";
import CheckIcon from "@material-ui/icons/Check";
import { green, grey } from "@material-ui/core/colors";
import { CaseStage, CaseTask } from "../services/caseService";

const CustomConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

const useCustomIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  },
}));

const useStyles = makeStyles((theme) => ({
  tasksContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  taskWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  completedTaskIcon: {
    color: green[500],
  },
  pendingTaskIcon: {
    color: grey[500],
  },
}));

type WorkflowCardProps = {
  stages: CaseStage[];
  tasks: CaseTask[];
};

const CustomIcon = ({ active, completed }) => {
  const classes = useCustomIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <CheckIcon />
    </div>
  );
};

const WorkflowCard = ({ stages = [], tasks = [] }: WorkflowCardProps) => {
  const classes = useStyles();
  const [showTasks, setShowTasks] = useState(false);

  const activeStepIndex = useMemo(
    () => stages.findIndex((stage: any) => stage.isActive),
    [stages]
  );
  const completedTasksCount = useMemo(
    () => tasks.filter((task: any) => task.isComplete).length,
    [tasks]
  );

  const toggleShowTasks = () => {
    setShowTasks((prevShow) => !prevShow);
    };
    
  return (
    <CardBase title="Workflow">
      <Stepper
        alternativeLabel
        activeStep={activeStepIndex}
        connector={<CustomConnector />}
      >
        {stages.map((s: any) => (
          <Step key={s.id}>
            <StepLabel StepIconComponent={CustomIcon}>{s.name}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {tasks.length ? (
        <div className={classes.tasksContainer}>
          <Typography variant="subtitle2">
            {completedTasksCount} out of {tasks.length} tasks completed.
          </Typography>
          <Button variant="text" color="secondary" onClick={toggleShowTasks}>
            {showTasks ? "Hide Tasks" : "Show Tasks"}
          </Button>
        </div>
      ) : null}
      {showTasks &&
        tasks.map((task: any) => (
          <div className={classes.taskWrapper} key={task.id}>
            <CheckIcon
              className={
                task.isComplete
                  ? classes.completedTaskIcon
                  : classes.pendingTaskIcon
              }
            />
            <Typography>{task.name}</Typography>
          </div>
        ))}
    </CardBase>
  );
};

export default WorkflowCard;
