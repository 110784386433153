import axios from "../utils/axios";
import stringUtils from "../utils/stringUtils";

const tileEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/tile`;

export type Tile = {
  id: number;
  displayName: string;
  internalRoute: string | null;
  clickUrl: string | null;
  icon: string | null;
  externalId: string;
  imageUrl: string | null;
  imageText: string | null;
  information: string | null;
};

function fetchQuickNavigation(accountExternalIds: string[]) {
  const queryString = stringUtils.buildQueryStringList(
    "externalIds",
    accountExternalIds
  );
  return axios.get<never, Tile[]>(
    `${tileEndpointUrl}/accounts/quicknav/${process.env.REACT_APP_CASENEST_APP_ID}/${queryString}`
  );
}

const tileService = {
  fetchQuickNavigation,
};

export default tileService;
