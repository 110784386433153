import CardBase from "./cards/CardBase";
import { Typography } from "@material-ui/core";
import React from "react";

const CaseDescriptionCard = ({ description }: { description?: string }) => {
  return (
    <CardBase title="Case Description">
      <Typography>{description || "No case description."}</Typography>
    </CardBase>
  );
};

export default CaseDescriptionCard;
