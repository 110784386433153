import { SET_IS_TUTORIAL_OPEN, SET_TUTORIAL } from "../types";

export function setIsTutorialOpen(isOpen) {
  return {
    type: SET_IS_TUTORIAL_OPEN,
    payload: {
      isOpen,
    },
  };
}

export function setTutorial(tutorial) {
  return {
    type: SET_TUTORIAL,
    payload: {
      tutorial,
    },
  };
}
