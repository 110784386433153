import React from "react";
import CardBase from "./cards/CardBase";
import { Chip, Typography, Box, makeStyles, Tooltip } from "@material-ui/core";
import chipStyles from "../theme/chips";
import { HomeWork, Business, Person } from "@material-ui/icons";
import useAccountDetails from "../hooks/queries/useAccountDetails";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { RootState } from "../redux/store";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
    },
    cardHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
    },
    cardContent: {
        marginBottom: "6px",
    },
    custDetail: {
        padding: theme.spacing(2),
    },
    treeLabel: {
        color: "black",
        cursor: "crosshair",
    },
    treeLabelNormal: {
        backgroundColor: "white",
        color: "black",
        cursor: "pointer",
    },
    centeredCard: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    }
}));

const AccountDetailsCard = () => {
    const chipClasses = chipStyles();
    const classes = useStyles();

    const { selectedSite } = useSelector(
        (state: RootState) => state.account
    );

    const accountExternalId: string | null = selectedSite?.externalId || null;

    const { data, isLoading, error, isFetching } = useAccountDetails({ accountExternalId });

    const cardContent = () => {
        if (data)
            return (
                <React.Fragment>
                    <Box display="flex">
                        <Box flexGrow={1} alignItems="center">
                            <Typography variant="h6">Account Details</Typography>
                        </Box>
                        <Box>
                            <Chip
                                className={
                                    data.status === "Active"
                                        ? chipClasses.green
                                        : data.status === "Pending"
                                            ? chipClasses.amber
                                            : chipClasses.root
                                }
                                label={data.status}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography className={classes.custDetail} variant="h6">
                            {data.name}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Box>
                            <HomeWork fontSize="large" />
                        </Box>
                        <Box flexGrow={1} ml={3}>
                            <Typography variant="body1">{data.address}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box display="flex" alignItems="center">
                            <Box>
                                <Person fontSize="large" />
                            </Box>
                            <Box flexGrow={1} ml={3} mr={6}>
                                {data.numberOfEmployees ? (
                                    <Tooltip
                                        title={
                                            "Employee count on salesforce" + data?.lastUpdated
                                                ? " (last updated - " +
                                                format(
                                                    new Date(data.lastUpdated as string),
                                                    "dd/MM/yyyy"
                                                ) +
                                                ")"
                                                : ""
                                        }
                                    >
                                        <Typography variant="body1">
                                            {data.numberOfEmployees}
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <Typography variant="body1">N/A</Typography>
                                )}
                            </Box>
                        </Box>
                        {data.numberOfSites > 1 ? (
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <Business fontSize="large" />
                                </Box>
                                <Box flexGrow={1} ml={3}>
                                    <Typography variant="body1">{data.numberOfSites}</Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box display="flex" alignItems="center" mt={3} ml={6}>
                                <Box>
                                    <Business fontSize="large" />
                                </Box>
                                <Box flexGrow={1} ml={3}>
                                    <Typography variant="body1">{data.numberOfSites}</Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </React.Fragment>
            );
        return <Typography>No Account Data</Typography>;
    };

    if (!accountExternalId)
        return (
            <CardBase
                fullHeight
            >
                <div className={classes.centeredCard}>
                    <Typography>
                        Please select a site
                    </Typography>
                </div>
            </CardBase>
        )

    return (
        <CardBase
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            fullHeight
        >
            {cardContent()}
        </CardBase>
    );
};

export default AccountDetailsCard;