import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MobileStepper,
  Typography,
} from "@material-ui/core";
import {
  Info,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { tutorialAppearanceModes } from "../../constants/tutorialAppearanceModes";
import useIsMobile from "../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  circles: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "60%",
    height: "auto",
    transform: "scaleX(-1)",
    zIndex: 0,
  },
  infoIcon: {
    verticalAlign: "top",
    marginRight: theme.spacing(1),
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  dots: {
    flexGrow: 1,
    background: "none",
  },
  closeButton: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    marginLeft: "auto",
    padding: 0,
    zIndex: 1,
  },
  dontShowAgainButton: {
    marginRight: "auto",
  },
  content: {
    "& p": {
      color: theme.palette.primary.main
    },
    "& .image": {
      margin: "0 auto",
    },
    "& .image_resized img": {
      width: "100%",
      height: "auto",
    },
    "& .image-style-side": {
      float: "right",
    },
    "& .image-inline": {
      alignItems: "flex-start",
      display: "inline-flex",
      maxWidth: "100%",
    },
  },
}));

function TutorialDialog({
  isOpen,
  onClose,
  tutorial,
  activeStepIndex,
  onDontShowAgain,
  onNextStep,
  onPreviousStep,
}) {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={isMobile}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <Typography className={classes.title}>
          <Info className={classes.infoIcon} />
          {tutorial?.title}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <img
          className={classes.circles}
          src={`${process.env.PUBLIC_URL}/images/circles_bottom_left_purple.svg`}
        />
      </DialogTitle>
      <DialogContent>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{
            __html: tutorial?.steps[activeStepIndex],
          }}
        ></div>
      </DialogContent>
      <DialogActions>
        {tutorial?.appearanceMode === tutorialAppearanceModes.EVERY_TIME && (
          <Button
            className={classes.dontShowAgainButton}
            color="secondary"
            variant="text"
            onClick={onDontShowAgain}
          >
            Don&apos;t show again
          </Button>
        )}
        {tutorial?.steps.length > 1 && (
          <MobileStepper
            className={classes.dots}
            variant="dots"
            position="static"
            steps={tutorial?.steps.length}
            activeStep={activeStepIndex}
            nextButton={
              <Button
                size="small"
                disabled={activeStepIndex + 1 === tutorial?.steps.length}
                onClick={onNextStep}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                disabled={activeStepIndex === 0}
                onClick={onPreviousStep}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default TutorialDialog;
