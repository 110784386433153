import React from "react";
import Avatar from "./Avatar";
import { Badge, Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import CardBase from "./cards/CardBase";

const useStyles = makeStyles(() => ({
  contactBox: {
    textAlign: "center",
  },
  userPhoto: {
    marginRight: "8px",
  },
}));

const ContactsCard = ({ contacts }) => {
  const classes = useStyles();

  const DisplayContacts = contacts.map((contact) => (
    <Box
      key={contact.id}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={3}
      mb={2}
      ml={2}
      mr={2}
      className={classes.contactBox}
    >
          <Tooltip title={contact.isPrimary ? "Primary Contact" : ""}>
              <>
                  {contact.isPrimary ? (
                      <Badge variant="dot" color="primary">
                          <Avatar
                              alt={contact.name}
                              src=""
                              size="50px"
                              className={classes.userPhoto}
                          />
                      </Badge>
                  ) : (
                      <Avatar
                          alt={contact.name}
                          src=""
                          size="50px"
                          className={classes.userPhoto}
                      />
                  )}              </>
       
      </Tooltip>
      <Typography variant="subtitle2">{contact.name}</Typography>
      <Typography variant="body2">{contact.position}</Typography>
    </Box>
  ));

  return (
    <CardBase title="Contacts">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {DisplayContacts}
      </Box>
    </CardBase>
  );
};

export default ContactsCard;
