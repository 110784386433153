import { Divider, makeStyles } from "@material-ui/core";
import TimeDurationTableCardItem from "./TimeDurationTableCardItem";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  divider: {
    width: "100%",
    margin: theme.spacing(1, 0),
  },
}));

type CaseTimeDurationTableProps = {
  isChargeable: boolean;
  isUnitised: boolean;
  hasBudgetSet: boolean;
  totalTime: number;
  nonChargeableTime: number;
  chargeableTime: number;
  budgetTime: number;
};

const CaseTimeDurationTable = ({
  isChargeable,
  isUnitised,
  hasBudgetSet,
  totalTime,
  chargeableTime,
  nonChargeableTime,
  budgetTime,
}: CaseTimeDurationTableProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TimeDurationTableCardItem
        title="Total"
        time={totalTime}
        isChargeable={false}
        isUnitised={isUnitised}
        isTotal
      />
      <Divider className={classes.divider} />
      <TimeDurationTableCardItem
        title="Non-Chargeable"
        time={nonChargeableTime}
        isChargeable={!isChargeable}
        isUnitised={isUnitised}
        isTotal={false}
      />
      <TimeDurationTableCardItem
        title="Chargeable"
        time={chargeableTime}
        isChargeable={isChargeable}
        isUnitised={isUnitised}
        isTotal={false}
      />
      {isChargeable && hasBudgetSet && (
        <TimeDurationTableCardItem
          title="Budget"
          time={budgetTime}
          isChargeable={false}
          isUnitised={isUnitised}
          isTotal={false}
        />
      )}
    </div>
  );
};

export default CaseTimeDurationTable;
