import {useMutation} from "react-query";
import caseService, {AddCustomerNoteParams, FetchCaseActivitiesResponse} from "../../services/caseService";
import queryKeys from "../../constants/queryKeys";
import reactQueryUtils from "../../utils/reactQueryUtils";
import {nanoid} from "@reduxjs/toolkit";
import {MISC} from "../../constants/caseActivityTypes";
import {directions} from "../../constants/directions";
import {useAuth} from "../../contexts/authContext";
import {getFileExtension} from "../../utils/fileNameUtils";
import {useSnackbar} from "notistack";
import {MiscActivityCategory} from "../../constants/miscActivityCategory";

const useAddCustomerNote = () => {
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    
    return useMutation({
        mutationFn: (note: AddCustomerNoteParams) => caseService.addCustomerNote(note),
        onMutate: async (note: AddCustomerNoteParams) => {
            const queryKey = [queryKeys.caseActivities, note.caseId];
            reactQueryUtils.client.cancelQueries(queryKey);
            const previousActivities = reactQueryUtils.client.getQueryData(queryKey);
            if (previousActivities)
                reactQueryUtils.client.setQueryData(queryKey, (prev : FetchCaseActivitiesResponse) => ({
                    ...prev,
                    caseActivities: [
                        {
                            caseId: note.caseId,
                            caseActivityId: nanoid(),
                            isSyncing: true,
                            itemType: MISC,
                            eventTime: new Date(),
                            content: note.message,
                            direction: directions.OTHER,
                            person: user?.details.firstName + " " + user?.details.lastName,
                            attachments: note.attachments.map(x => ({
                                reference: x.id,
                                filename: x.file?.name,
                                contentType: x.file?.type,
                                extension: getFileExtension(x.file?.name || "").replaceAll(".", "")
                            })),
                            miscActivityTypeDescription: "Customer Note",
                            miscActivityCategory: MiscActivityCategory.CustomerNote,
                            activityDescription: note.subject
                        },
                        ...prev.caseActivities
                    ]
                }));            
            return { previousActivities, queryKey };
        },
        onError: (err, variables, context) => {
            enqueueSnackbar("Failed to add Customer Note", { variant: "error" });
            if (context?.previousActivities)
                reactQueryUtils.client.setQueryData(context.queryKey, context.previousActivities);
        },
        onSuccess: () => { enqueueSnackbar("Customer Note added") },
        onSettled: (data, err, newDetails, context) => {
            if (context?.queryKey)
                reactQueryUtils.client.invalidateQueries(context.queryKey);
        },
    });
}
    

export default useAddCustomerNote;