import React, { useState } from "react";
import {
  IconButton,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import CachedIcon from "@material-ui/icons/Cached";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "../../contexts/authContext";
import { RootState } from "../../redux/store";

const useStyles = makeStyles((theme) => ({
  userMenuText: {
    marginLeft: theme.spacing(1),
  },
}));

function UserMenu() {
  const classes = useStyles();
  const history = useHistory();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  const { rootAccounts } = useSelector((state: RootState) => state.account);
  const { logout } = useAuth();

  const goToAccountSelect = () => {
    history.push("/account-select");
  };

  const handleMenuToggle = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="User menu"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleMenuToggle}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
      >
        {rootAccounts.length > 1 && (
          <MenuItem onClick={goToAccountSelect}>
            <CachedIcon fontSize="small" />
            <ListItemText
              className={classes.userMenuText}
              primary="Change Account"
            />
          </MenuItem>
        )}
        <MenuItem onClick={logout}>
          <ExitToAppIcon fontSize="small" />
          <ListItemText className={classes.userMenuText} primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
