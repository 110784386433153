import React, { useState } from "react";
import { useSelector } from "react-redux";
import CardBase from "./cards/CardBase";
import useELTeam from "../hooks/queries/useELTeam";
import {
  Box,
  Typography,
  makeStyles,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import Avatar from "./Avatar";
import { Phone } from "@material-ui/icons";
import RequestCallbackDialog from "./RequestCallbackDialog";
import { RootState } from "../redux/store";

const useStyles = makeStyles((theme) => ({
  nameLabel: {
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  avatarBoxed: {
    border: "none",
  },
  avatarGroup: {
    flexWrap: "wrap",
  },
}));

const ELTeamCard = () => {
  const classes = useStyles();

  const { selectedSite, sites } = useSelector(
    (state: RootState) => state.account
  );

  const accountExternalIds = selectedSite
    ? [selectedSite.externalId]
    : Object.keys(sites);

  const [isOpen, setIsOpen] = useState(false);

  const {
    data: advisers,
    isLoading,
    error,
  } = useELTeam({ accountExternalIds });

  return (
    <>
      <CardBase
        title="My Legal Team"
        isLoading={isLoading}
        error={error}
        rightComponent={
          <Tooltip
            title={
              selectedSite === null
                ? "Select a site to request a callback"
                : "Request a callback"
            }
          >
            <span>
              <IconButton
                disabled={selectedSite === null}
                onClick={() => setIsOpen(true)}
              >
                <Phone />
              </IconButton>
            </span>
          </Tooltip>
        }
        fullHeight
      >
        <AvatarGroup className={classes.avatarGroup} max={4}>
          {advisers &&
            advisers.map((a) => (
              <Box
                className={classes.avatarBoxed}
                display="flex"
                flexDirection="column"
                alignItems="center"
                flex="1"
                key={a.userId}
                my={3}
                mx={4}
              >
                <Avatar src={a.photo} alt={a.name} size="50px" />
                <Typography className={classes.nameLabel} variant="subtitle2">
                  {a.name}
                </Typography>
              </Box>
            ))}
        </AvatarGroup>
      </CardBase>
      <RequestCallbackDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        advisers={advisers}
      />
    </>
  );
};

export default ELTeamCard;
